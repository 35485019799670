import React, { Component } from 'react';
import { Dictionary, map } from 'lodash';

import { User, UserCalculator } from 'salary-shared';
import { Link } from 'react-router-dom';
import { url } from '../../sitemap';

import './PageAdminUsers.scss';
import { currentMonth } from '../../utils/utils';
import { fmt } from '../../utils/format';

export interface PageUsersProps {
	users: Dictionary<User>;
	onInit: () => void;
}

export interface PageUsersState {
}

export class PageAdminUsers extends Component<PageUsersProps, PageUsersState> {
	componentDidMount() {
		this.props.onInit();
	}

	render() {
		const { users } = this.props;

		return (
			<div className='PageAdminUsers'>
				<h2>Сотрудники</h2>
				<table>
					<thead>
					<tr>
						<th>Имя</th>
						<th>Начало работы</th>
						<th>Стаж</th>
						<th>Алгоритм</th>
						<th>Рейт</th>
					</tr>
					</thead>
					<tbody>
					{map(users, u => (
						<tr key={u!._id}>
							<td><Link to={url.admin.users.current(u!._id)}>{u!.name}</Link></td>
							<td>{u.start}</td>
							<td>{fmt.seniority(UserCalculator.seniority(u, currentMonth))}</td>
							<td>{fmt.salaryMode(UserCalculator.modeForDate(u.salary, currentMonth))}</td>
							<td>{fmt.ueh(UserCalculator.rateForDate(u.rate, currentMonth))}</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		);
	}
}
