import React, { Component, Fragment } from 'react';
import { fmt } from '../../utils/format';

import './RowSalaryTotals.scss';
import { monthWorkHours } from 'salary-shared';

export interface Totals {
	src: {
		seniority: number;
		seniorityH: number;
		prognosis: number;
		worked: number;
		vacation: number;
	};
	wage: {
		total: number;
		insurance: number;
		seniority: number;
		work: number;
		bonus: number;
	};
	payment: {
		total: number;
		advance: number;
		wire: number;
		cash: number;
		reimbursement: number;
	};
	arrears: {
		before: number;
		after: number;
		diff: number;
	};
}

export interface RowSalaryTotalsProps {
	total: Totals;
}

export interface RowSalaryTotalsState {
}

export class RowSalaryTotals extends Component<RowSalaryTotalsProps, RowSalaryTotalsState> {
	render() {
		const { total } = this.props;

		return (
			<Fragment>
				<tr className='RowSalaryTotals'>
					<td rowSpan={2} className='RowSalaryTotals__name'>
						Итого:
					</td>
					<td rowSpan={2} className='RowSalaryTotals__src'>
						{fmt.seniority(total.src.seniority)}
						{total.src.seniorityH && <Fragment>
							<br />
							{fmt.seniorityH(total.src.seniorityH)}
							<br />
							{fmt.seniorityHM(total.src.seniorityH, monthWorkHours)}
						</Fragment>}
					</td>
					<td rowSpan={2} className='RowSalaryTotals__src'>
						{fmt.hours(total.src.prognosis)}
					</td>
					<td rowSpan={2} className='RowSalaryTotals__src'>
						{fmt.hours(total.src.worked)}
					</td>
					<td rowSpan={2} className='RowSalaryTotals__src'>
						{fmt.hours(total.src.vacation)}
					</td>
					<td colSpan={4} className='RowSalaryTotals__total'>
						{fmt.rur(total.wage.total)}
					</td>
					<td colSpan={3} className='RowSalaryTotals__total'>
						{fmt.rur(total.payment.total)}
					</td>
					<td colSpan={3} className='RowSalaryTotals__total'>
						{fmt.rur(total.arrears.after)}
					</td>
				</tr>
				<tr className='RowSalaryTotals'>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.wage.insurance)}</td>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.wage.seniority)}</td>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.wage.work)}</td>
					<td className='RowSalaryTotals__detail RowSalaryTotals__detail--right-border'>{fmt.rur(total.wage.bonus)}</td>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.payment.advance)}</td>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.payment.wire)}</td>
					<td className='RowSalaryTotals__detail RowSalaryTotals__detail--right-border'>
						{fmt.rur(total.payment.cash)}
					</td>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.arrears.before)}</td>
					<td className='RowSalaryTotals__detail'>{fmt.rur(total.arrears.diff)}</td>
					<td className='RowSalaryTotals__detail RowSalaryTotals__detail--right-border'>
						{fmt.rur(total.payment.reimbursement)}
					</td>
				</tr>
			</Fragment>
		);
	}
}
