import { connect } from 'react-redux';
import { PageUserVacations as Page, PageUserVacationsProps } from '../components/PageUserVacations/PageUserVacations';
import { IState, State } from '../models/State';
import { meId } from '../utils/utils';
import { concat, find, sortBy } from 'lodash';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { moment, SalaryState } from 'salary-shared';
import { DispatchFunc } from '../utils/redux';


export const PageUserVacations = connect<{}, {}, PageUserVacationsProps, IState>(
	(state, ownProps) => {
		const userId = meId();
		return {
			me: State.entity.users(state)[userId],
			vacations: sortBy(State.model.vacations.byUser(state)[userId], 'date'),
			salary: find(sortBy(State.model.salaries.byUser(state)[userId], 'date'), s => s.state === SalaryState.FUTURE),
		};

	},
	(dispatch: DispatchFunc, ownProps) => {
		const userId = meId();

		return {
			onInit: () =>
				Promise.all([
					Api.users.find({
						_id: userId,
					}),
					Api.vacations.byUserForPeriod(userId, '2019-01-01', moment().format('YYYY-12-31')),
					Api.salaries.byUserForPeriod(userId, moment().add(-2, 'months').format('YYYY-MM-01'), moment().format('YYYY-12-31')),
				])
					.then(resps =>
						concat(
							ActionEntity.loadResp(Schema.users!)(resps[0]),
							ActionEntity.loadResp(Schema.vacations!)(resps[1]),
							ActionEntity.loadResp(Schema.salaries!)(resps[2]),
						),
					)
					.then(dispatch),
		};

	},
)(Page);
