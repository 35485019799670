import React, { Component, Fragment } from 'react';

import './RowSalaryHead.scss';

export class RowSalaryHead extends Component {
	render() {
		return (<Fragment>
			<tr className='RowSalaryHead'>
				<th rowSpan={2} className='RowSalaryHead__total'>Сотрудник</th>
				<th colSpan={4} className='RowSalaryHead__total'>Исходные</th>
				<th colSpan={4} className='RowSalaryHead__total'>Начислено</th>
				<th colSpan={3} className='RowSalaryHead__total'>Выплачено</th>
				<th colSpan={3} className='RowSalaryHead__total'>Остатки</th>
			</tr>
			<tr className='RowSalaryHead'>
				<th className='RowSalaryHead__detail'>Стаж</th>
				<th className='RowSalaryHead__detail'>Прогноз</th>
				<th className='RowSalaryHead__detail'>Выработка</th>
				<th className='RowSalaryHead__detail RowSalaryHead__detail--right-border'>Отпуск</th>
				<th className='RowSalaryHead__detail'>Страховка</th>
				<th className='RowSalaryHead__detail'>За стаж</th>
				<th className='RowSalaryHead__detail'>За работу</th>
				<th className='RowSalaryHead__detail RowSalaryHead__detail--right-border'>Бонусы</th>
				<th className='RowSalaryHead__detail'>Авансом</th>
				<th className='RowSalaryHead__detail'>Карточка</th>
				<th className='RowSalaryHead__detail RowSalaryHead__detail--right-border'>Наличные</th>
				<th className='RowSalaryHead__detail'>Прошлые</th>
				<th className='RowSalaryHead__detail'>Округления</th>
				<th className='RowSalaryHead__detail RowSalaryHead__detail--right-border'>Погашения</th>
			</tr>
		</Fragment>);
	}
}
