import { capitalize } from 'lodash';

import { moment, SalaryMode, SalaryState } from 'salary-shared';

const rurFmt = Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' });
const numFmt = Intl.NumberFormat('ru-RU');

export const fmt = {
	rur: (amount?: number) => {
		return rurFmt.format((amount || 0) / 100);
	},
	amount: (amount?: number, fixed?: number) => {
		return numFmt.format(+(amount ?? 0).toFixed(fixed ?? 0));
	},
	ue: (amount?: number) => {
		return fmt.rur(amount).replace('₽', 'у.е.');
	},
	ueh: (amount?: number) => {
		return fmt.rur(amount).replace('₽', 'у.е./ч.');
	},
	hours: (amount?: number) => {
		return fmt.rur((amount || 0) * 100).replace('₽', 'ч.');
	},
	month: (d: string) => {
		return capitalize(moment(d).format('MMMM YYYY'));
	},
	date: (d: string) => {
		return moment(d).format('D MMMM YYYY');
	},
	seniority: (months: number) => {
		return `${fmt.amount(months)} м.`;
	},
	seniorityH: (hours: number) => {
		return `${fmt.amount(hours)} ч.`;
	},
	seniorityHM: (hours: number, monthHours: number) => {
		return `${fmt.amount(hours / monthHours, 2)} чм.`;
	},
	days: (days: number) => {
		return `${days} д.`;
	},
	salaryMode: (mode: SalaryMode) => {
		switch (mode) {
			case SalaryMode.TIME_AND_MATERIAL:
				return 'Время/работа';

			case SalaryMode.INSURED_SENIORITY:
				return 'Страховка за стаж';

			case SalaryMode.INSURED_2024:
				return 'Страховка 2024';

			case SalaryMode.TSR:
				return 'Архивное время';
		}
	},
	salaryModeDesc: (mode: SalaryMode) => {
		switch (mode) {
			case SalaryMode.TIME_AND_MATERIAL:
				return 'Алгоритм для внештатных сотрудников. Сколько заработал, столько и получил. Формула учитывает отработанное время по оговоренному рейту.';

			case SalaryMode.INSURED_SENIORITY:
				return 'Алгоритм для штатных сотрудников. В алгоритме учитывается стаж сотрудника, в соответствие с которым начисляется премия за стаж и алгоритм сглаживания выработки.';

			case SalaryMode.INSURED_2024:
				return 'Универсальный алгоритм в редакции 2024 года. В алгоритме учитывается стаж сотрудника, его трудовой вклад за всю историю работы, в соответствие с которым начисляется премия за стаж и алгоритм сглаживания выработки.';

			case SalaryMode.TSR:
				return 'Просто архивная запись об учтённом отработанном времени с доисторических времён, нужно для более точного учёта полной выработки.';
		}
	},
	salaryState: (state: SalaryState) => {
		switch (state) {
			case SalaryState.FUTURE:
				return 'расчёт';
			case SalaryState.ARCHIVE:
				return 'архив';
		}
	},
};
