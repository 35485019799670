import { connect } from 'react-redux';

import { concat, filter, partial, head, sortBy, reverse, map, Dictionary } from 'lodash';

import { PageUserSalary as Page, PageUserSalaryProps } from '../components/PageUserSalary/PageUserSalary';
import { IState, State } from '../models/State';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { Course } from 'salary-shared';
import { DispatchFunc } from '../utils/redux';
import { month } from '../utils/utils';

const pForMonth = (month: string, o: { date: string }) => o.date === month;

const courseForDate = (courses: Dictionary<Course>, date: string) => {
	let result: any = filter(courses, s => s!._id <= date);
	result = sortBy(result, '_id');
	result = reverse(result);
	result = map(result, 'price');
	result = head(result);
	return result;
};

export const PageAdminUserSalary = connect<{}, {}, PageUserSalaryProps, IState>(
	(state, ownProps) => {
		const userId = ownProps.match.params.id;
		const mon = month(ownProps.match.params.month);

		return {
			user: State.entity.users(state)[userId],
			showTitle: true,
			salary: head(filter(State.model.salaries.byUser(state)[userId], partial(pForMonth, mon))),
			dateCourse: courseForDate(State.entity.courses(state), mon),
		};
	},
	(dispatch: DispatchFunc, ownProps) => {
		const userId = ownProps.match.params.id;
		const mon = month(ownProps.match.params.month);

		return {
			onInit: () =>
				Promise.all([
					Api.users.find({
						_id: userId,
					}),
					Api.salaries.createForDate(mon),
					Api.courses.forDate(mon),
				])
					.then(resps =>
						concat(
							ActionEntity.loadResp(Schema.users!)(resps[0]),
							ActionEntity.loadResp(Schema.salaries!)(resps[1]),
							ActionEntity.loadResp(Schema.courses!)(resps[2])
						)
					)
					.then(dispatch),
		};
	}
)(Page);
