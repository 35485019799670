import {ReducerClass, Action, Immutable} from 'reducer-class';

import {ActionUserLogin} from './ActionsUser';
import { User } from 'salary-shared';

export interface UserState {
	me?: User
}

export class ReducerUser extends ReducerClass<UserState> {

	initialState: UserState = {
		me: undefined
	};

	@Action(ActionUserLogin)
	login(state: Immutable<UserState>, draft: UserState, action: ActionUserLogin) {
		draft.me = action.payload;
		return undefined;
	}

}