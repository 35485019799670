import { connect } from 'react-redux';
import { PageHome as Page, PageHomeProps } from '../components/PageHome/PageHome';
import { IState, State } from '../models/State';
import { meId } from '../utils/utils';
import { Api } from '../api/Api';
import { concat } from 'lodash';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { DispatchFunc } from '../utils/redux';


export const PageHome = connect<{}, {}, PageHomeProps, IState>(
	(state, ownProps) => {
		const userId = meId();

		return {
			me: State.entity.users(state)[userId],
			courses: State.entity.courses(state),
		};
	},
	(dispatch: DispatchFunc, ownProps) => ({
		onInit: () =>
			Promise.all([
				Api.users.find({
					_id: meId(),
				}),
			])
				.then(resps =>
					concat(ActionEntity.loadResp(Schema.users!)(resps[0])),
				)
				.then(dispatch),
	}),
)(Page);
