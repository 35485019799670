import React, { Component } from 'react';
import { default as classnames } from 'classnames';
import { Totals } from './RowSalaryTotals';
import { fmt } from '../../utils/format';

import './SalaryStatus.scss';

export interface SalaryStatusProps {
	total: Totals;
}

enum State {
	SUCCESS,
	WARNING,
	ERROR,
}

export class SalaryStatus extends Component<SalaryStatusProps> {
	render() {
		const { total } = this.props;

		const { diff } = total.arrears;

		const state = diff < 0 ? State.SUCCESS : diff < 1000 ? State.WARNING : State.ERROR;

		return (
			<div
				{...{
					className: classnames('SalaryStatus', {
						'SalaryStatus--success': State.SUCCESS === state,
						'SalaryStatus--warning': State.WARNING === state,
						'SalaryStatus--error': State.ERROR === state,
					}),
				}}
			>
				{State.SUCCESS === state && (
					<p>
						Все текущие зарплаты выплачены. Если есть свободные деньги, то их можно распределить на погашение
						задолженностей.
					</p>
				)}
				{State.WARNING === state && <p>В целом зарплаты выплачены, но есть небольшие ошибки округления.</p>}
				{State.ERROR === state && (
					<p>
						Зарплата ещё не выплачена. На выплату текущей зарплаты необходим бюджет в{' '}
						<strong>{fmt.rur(total.arrears.diff)}</strong>.
					</p>
				)}
			</div>
		);
	}
}
