import { Dictionary } from 'lodash';
import { schema } from 'normalizr';

const tests = new schema.Entity('tests', {}, { idAttribute: '_id' });

const users = new schema.Entity('users', {}, { idAttribute: '_id' });

const courses = new schema.Entity('courses', {}, { idAttribute: '_id' });

const salaries = new schema.Entity('salaries', {}, { idAttribute: '_id' });

const vacations = new schema.Entity('vacations', {}, { idAttribute: '_id' });

export const Schema: Dictionary<schema.Entity> = {
	tests,
	users,
	courses,
	salaries,
	vacations,
};
