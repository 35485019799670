import React, { Component } from 'react';
import {
	concat,
	Dictionary,
	filter,
	find,
	flatten,
	forEach,
	groupBy,
	keys,
	last,
	map,
	sortBy,
	startsWith,
	sum,
	sumBy,
	uniq,
	values,
} from 'lodash';

import {
	Course,
	moment,
	Payment,
	PaymentsGroup,
	Salary,
	SalaryCalculator,
	SalaryPayment,
	SalaryPaymentCalculator,
	SalarySourceCalculator,
	SalaryWageCalculator,
	User,
	UserCalculator,
} from 'salary-shared';
import { fmt } from '../../utils/format';
import { MatchParams } from '../../utils/redux';
import { RowSalary } from './RowSalary';

import './PageAdminSalary.scss';
import { RowSalaryHead } from './RowSalaryHead';
import { RowSalaryTotals } from './RowSalaryTotals';
import { SalarySummary } from './SalarySummary';
import { SalaryStatus } from './SalaryStatus';

export interface PageAdminSalaryProps extends MatchParams<{ month: string }> {
	month: string;
	users: Dictionary<User>;
	salaries: Salary[];
	dateCourse: Course;
	onInit: () => void;
}

export interface PageAdminSalaryState {
}

function _salaryPaymentsForDate(salary: Salary, date: string): Payment[] {
	const p = salary.payment || new SalaryPayment();
	return filter(concat(p.cash || [], p.wire || [], p.reimbursement || []), {
		date: date,
	});
}

function _salaryPaymentGroupForComment(comment?: string): string {
	if (startsWith(comment, 'ООО ')) {
		return comment!;
	} else if (startsWith(comment, 'ИП ')) {
		return comment!;
	} else {
		return 'Наличные';
	}
}

function _salaryPaymentsGroupsForDate(salary: Salary, date: string): string[] {
	const payments = _salaryPaymentsForDate(salary, date);
	const res = map(payments, p => _salaryPaymentGroupForComment(p.comment));
	return uniq(res);
}

function _salarySumPaymentsForDateGroup(salary: Salary, date: string, group: string): Payment {
	const result = new Payment();
	result.date = date;
	result.comment = salary.user;
	result.amount = 0;

	const payments = filter(
		_salaryPaymentsForDate(salary, date),
		p => group === _salaryPaymentGroupForComment(p.comment),
	);

	result.amount = sumBy(payments, 'amount');

	return result;
}

interface Row {
	user: User,
	salary: Salary
}

export class PageAdminSalary extends Component<PageAdminSalaryProps, PageAdminSalaryState> {
	state = {};

	componentDidMount() {
		this.props.onInit();
	}

	componentDidUpdate(prevProps: Readonly<PageAdminSalaryProps>, prevState: Readonly<PageAdminSalaryState>, snapshot?: any) {
		if (prevProps.month !== this.props.month) {
			this.props.onInit();
		}
	}

	_calcTotals = (rows: Row[]) => {
		return {
			src: {
				seniority: sum(map(rows, s => UserCalculator.seniority(s.user, s.salary.date))),
				seniorityH: sum(map(rows, s => s.salary.src.seniority ?? 0)),
				prognosis: sumBy(rows, 'salary.src.prognosis'),
				worked: sumBy(rows, 'salary.src.worked'),
				vacation: sum(map(rows, s => SalarySourceCalculator.vacationHours(s.salary.src))),
			},
			wage: {
				total: sum(map(rows, s => SalaryWageCalculator.total(s.salary.wage))),
				insurance: sumBy(rows, 'salary.wage.insurance'),
				seniority: sumBy(rows, 'salary.wage.seniority'),
				work: sumBy(rows, 'salary.wage.work'),
				bonus: sum(map(rows, s => SalaryWageCalculator.bonuses(s.salary.wage))),
			},
			payment: {
				total: sum(map(rows, s => SalaryPaymentCalculator.total(s.salary.payment))),
				advance: sum(map(rows, s => SalaryPaymentCalculator.sum((s.salary.payment || {}).advance))),
				wire: sum(map(rows, s => SalaryPaymentCalculator.sum((s.salary.payment || {}).wire))),
				cash: sum(map(rows, s => SalaryPaymentCalculator.sum((s.salary.payment || {}).cash))),
				reimbursement: sum(map(rows, s => SalaryPaymentCalculator.sum((s.salary.payment || {}).reimbursement))),
			},
			arrears: {
				before: sumBy(rows, 'salary.arrears'),
				after: sum(map(rows, s => SalaryCalculator.arrearsAfter(s.salary))),
				diff: sum(map(rows, s => SalaryWageCalculator.total(s.salary.wage) - SalaryPaymentCalculator.total(s.salary.payment))),
			},
		};
	};

	_calcLastPaymentDate = (pred: (s: Salary) => Payment[]): string => {
		const { salaries } = this.props;

		const payments = groupBy(flatten(map(salaries, pred)), 'date');

		return last(keys(payments).sort()) || moment(new Date()).format('YYYY-MM-DD');
	};

	_calcPaymentsGroups = (date: string): PaymentsGroup[] => {
		const { salaries, users } = this.props;
		const titles = uniq(flatten(map(salaries, s => _salaryPaymentsGroupsForDate(s, date))));
		return map(titles, t => {
			const res = new PaymentsGroup();
			res.title = t;
			res.payments = map(
				filter(
					map(salaries, s => _salarySumPaymentsForDateGroup(s, date, t)),
					p => p.amount !== 0,
				),
				p => ({
					date: date,
					amount: p.amount,
					comment: users?.[p.comment!]?.name,
				}),
			);
			return res;
		});
	};

	_calcCashPaymentDate = (): string => {
		return this._calcLastPaymentDate(s => {
			const p = s.payment || new SalaryPayment();
			return concat(p.cash || [], p.reimbursement || []);
		});
	};

	_calcCashPaymentGroups = (): PaymentsGroup[] => {
		return this._calcPaymentsGroups(this._calcCashPaymentDate());
	};

	_calcWirePaymentDate = (): string | undefined => {
		const cDate = this._calcCashPaymentDate();
		const wDate = this._calcLastPaymentDate(s => {
			const p = s.payment || new SalaryPayment();
			return p.wire || [];
		});
		if (wDate > cDate) {
			return wDate;
		}
		return undefined;
	};

	_calcWirePaymentGroups = (): PaymentsGroup[] => {
		const wDate = this._calcWirePaymentDate();
		if (wDate) {
			return this._calcPaymentsGroups(wDate);
		}
		return [];
	};

	render() {
		const { month, users, salaries } = this.props;

		const rows: Row[] = [];
		forEach(sortBy(values(users), 'start'), u => {
			const s = find(salaries, s => s.user === u._id);
			if (s) {
				rows.push({
					user: u,
					salary: s,
				});
			}
		});
		const total = this._calcTotals(rows);

		return (
			<div className='PageAdminSalary'>
				<h2>Зарплата {fmt.month(month)}</h2>
				<table className='PageAdminSalary__table'>
					<thead>
					<RowSalaryHead />
					</thead>
					<tbody>
					{map(rows, r => (
						<RowSalary
							{...{
								key: r.salary._id.toString(),
								user: r.user,
								month: month,
								salary: r.salary,
							}}
						/>
					))}
					<RowSalaryTotals
						{...{
							total: total,
						}}
					/>
					</tbody>
				</table>
				<SalaryStatus
					{...{
						total: total,
					}}
				/>
				<SalarySummary
					{...{
						lastPaymentDate: this._calcCashPaymentDate(),
						payments: this._calcCashPaymentGroups(),
					}}
				/>
				<SalarySummary
					{...{
						lastPaymentDate: this._calcWirePaymentDate(),
						payments: this._calcWirePaymentGroups(),
					}}
				/>
			</div>
		);
	}
}
