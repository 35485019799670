import { connect } from 'react-redux';

import { concat, reverse, sortBy } from 'lodash';

import { PageUserSalaryList as Page, PageUserSalaryListProps } from '../components/PageUserSalary/PageUserSalaryList';
import { IState, State } from '../models/State';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { DispatchFunc } from '../utils/redux';
import { meId } from '../utils/utils';

export const PageUserSalaryList = connect<{}, {}, PageUserSalaryListProps, IState>(
	(state, ownProps) => {
		const userId = meId();

		return {
			user: State.entity.users(state)[userId],
			salaries: reverse(sortBy(State.model.salaries.byUser(state)[userId], 'date')),
		};
	},
	(dispatch: DispatchFunc, ownProps) => {
		const userId = meId();

		return {
			onInit: () => {
				return Promise.all([
					Api.users.find({
						_id: userId,
					}),
					Api.salaries.byUserForPeriod(userId, '2000-01', '5000-12'),
				])
					.then(resps =>
						concat(
							ActionEntity.loadResp(Schema.users!)(resps[0]),
							ActionEntity.loadResp(Schema.salaries!)(resps[1]),
						),
					)
					.then(dispatch);
			},
		};
	},
)(Page);
