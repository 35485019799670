import {ReducerClass, Action, Immutable} from 'reducer-class';

import {ActionLockProgress, ActionLockTransparent, ActionLockUnlock} from './ActionsLock';

export interface LockState {
	mode: string
}

export class ReducerLock extends ReducerClass<LockState> {

	initialState = {
		mode: ''
	};

	@Action(ActionLockTransparent)
	lockTransparent(state: Immutable<LockState>, draft: LockState, action: ActionLockTransparent) {
		draft.mode = 'active transparent';
		return undefined;
	}

	@Action(ActionLockProgress)
	lockProgress(state: Immutable<LockState>, draft: LockState, action: ActionLockProgress) {
		draft.mode = 'active';
		return undefined;
	}

	@Action(ActionLockUnlock)
	unlock(state: Immutable<LockState>, draft: LockState, action: ActionLockUnlock) {
		draft.mode = '';
		return undefined;
	}
}