export const path = {
	home: '/',
	user: {
		info: '/info/',
		vacations: '/vacations/',
		salary: {
			list: '/salary/',
			month: '/salary/:month/',
		},
	},
	admin: {
		vacations: {
			current: '/admin/vacations/',
			year: '/admin/vacations/:year',
		},
		salary: {
			current: '/admin/salary/',
			month: '/admin/salary/:month/',
		},
		users: {
			home: '/admin/users/',
			current: '/admin/users/:id/',
			year: '/admin/users/:id/:year/',
			salary: '/admin/users/:id/salary/:month/',
		},
	},
};

export const url = {
	home: path.home,
	user: {
		info: path.user.info,
		vacations: path.user.vacations,
		salary: {
			list: path.user.salary.list,
			month: (month: string) => path.user.salary.month
				.replace(':month', month),
		},
	},
	admin: {
		vacations: {
			current: path.admin.vacations.current,
			year: (year: string) => path.admin.vacations.year
				.replace(':year', year),
		},
		salary: {
			current: path.admin.salary.current,
			month: (month: string) => path.admin.salary.month
				.replace(':month', month),
		},
		users: {
			home: path.admin.users.home,
			current: (id: string) => path.admin.users.current
				.replace(':id', id),
			year: (id: string, year: string) => path.admin.users.year
				.replace(':id', id)
				.replace(':year', year),
			salary: (id: string, month: string) => path.admin.users.salary
				.replace(':id', id)
				.replace(':month', month),
		},
	},
};
