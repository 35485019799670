import {AppAction} from './AppAction';

export class ActionLockTransparent extends AppAction {
}

export class ActionLockProgress extends AppAction {
}

export class ActionLockUnlock extends AppAction {
}

export const ActionLock = {
	transparent: () => new ActionLockTransparent(),
	progress: () => new ActionLockProgress(),
	unlock: () => new ActionLockUnlock()
};