import React, { Component, Fragment } from 'react';

import { head, isNil, join, map, split } from 'lodash';

import {
	Salary,
	SalaryCalculator,
	SalaryMode,
	SalaryPaymentCalculator,
	SalaryState,
	SalaryWageCalculator,
	User,
	UserCalculator,
} from 'salary-shared';
import { fmt } from '../../utils/format';
import { UserSalaryInsuredSeniority } from './UserSalaryInsuredSeniority';
import { UserSalaryTimeAndMaterial } from './UserSalaryTimeAndMaterial';
import { RowDetails } from './RowDetails';
import { RowSection } from './RowSection';
import { RowPaymentGroup } from './RowPaymentGroup';

import './PageUserSalary.scss';
import { MatchParams } from '../../utils/redux';
import { url } from '../../sitemap';
import { Link } from 'react-router-dom';
import { UserSalaryTsr } from './UserSalaryTsr';
import { UserSalaryInsured2024 } from './UserSalaryInsured2024';

export interface PageUserSalaryProps extends MatchParams<{ id: string, month: string }> {
	user: User;
	salary?: Salary;
	dateCourse: number;
	showTitle: boolean;
	onInit: () => void;
}

export interface PageUserSalaryState {
}

const abbr = (name: string): string => join(
	map(
		split(name, /[.\s]/),
		head,
	),
	'',
);

export class PageUserSalary extends Component<PageUserSalaryProps, PageUserSalaryState> {

	componentDidMount() {
		this.props.onInit();
	}

	componentDidUpdate(prevProps: Readonly<PageUserSalaryProps>, prevState: Readonly<PageUserSalaryState>, snapshot?: any) {
		if (prevProps.match.params.month !== this.props.match.params.month) {
			this.props.onInit();
		}
	}

	render() {
		const { user, salary, dateCourse, showTitle } = this.props;

		if (isNil(user) || isNil(salary)) {
			return (<div className='PageUserSalary'>
				<h2>Загружается...</h2>
			</div>);
		} else {
			document.title = `${abbr(user.name)}.${salary.date}`;
			const mode = salary.mode || UserCalculator.modeForDate(user.salary, salary.date);
			const rate = salary.rate || UserCalculator.rateForDate(user.rate, salary.date);
			const course = salary.course || dateCourse;
			const hasArrears = salary.arrears || SalaryCalculator.arrearsAfter(salary) || undefined;
			return (<div className='PageUserSalary'>
				{showTitle && <h2 className='PageUserSalary__title'>{user.name}</h2>}
				<h4 className='PageUserSalary__subtitle'>Заработная плата
					за <em>{fmt.month(salary.date)}</em> года <small>({SalaryState.FUTURE === salary.state ? 'расчёт' : 'архив'})</small>
				</h4>
				<h6>Алгоритм расчёта: <strong>{fmt.salaryMode(mode)}</strong> &mdash; ({fmt.salaryModeDesc(mode)})</h6>
				<table className='PageUserSalary__table'>
					<tbody>
					{SalaryMode.TSR === mode && <UserSalaryTsr {...{
						salary,
					}} />}
					{SalaryMode.TSR !== mode && <Fragment>
						<RowSection {...{
							title: 'Начислено',
							amount: SalaryWageCalculator.total(salary.wage),
						}} />
						{SalaryMode.INSURED_SENIORITY === mode && <UserSalaryInsuredSeniority {...{
							user,
							salary,
							rate,
							course,
						}} />}
						{SalaryMode.TIME_AND_MATERIAL === mode && <UserSalaryTimeAndMaterial {...{
							user,
							salary,
							rate,
							course,
						}} />}
						{SalaryMode.INSURED_2024 === mode && <UserSalaryInsured2024 {...{
							user,
							salary,
							rate,
							course,
						}} />}
						{map(salary.wage.bonus, (b, idx) => <RowDetails {...{
							key: `bonus-${idx}`,
							caption: b.name,
							data: fmt.rur(b.amount),
							comment: b.comment,
						}} />)}
						<RowSection {...{
							title: 'Выплачено',
							amount: SalaryPaymentCalculator.total(salary.payment),
						}} />
						{salary.payment && salary.payment.advance && <RowPaymentGroup {...{
							title: 'Авансом',
							data: salary.payment.advance,
						}} />}
						{salary.payment && salary.payment.wire && <RowPaymentGroup {...{
							title: 'На карточку',
							data: salary.payment.wire,
						}} />}
						{salary.payment && salary.payment.cash && <RowPaymentGroup {...{
							title: 'Наличными',
							data: salary.payment.cash,
						}} />}
						{hasArrears && <Fragment>
							<RowSection {...{
								title: 'Остатки',
								amount: SalaryCalculator.arrearsAfter(salary),
							}} />
							<RowDetails {...{
								caption: 'С прошлого месяца',
								data: fmt.rur(salary.arrears),
							}} />
							<RowDetails {...{
								caption: 'Погрешности округления',
								data: fmt.rur(SalaryWageCalculator.total(salary.wage) - SalaryPaymentCalculator.total(salary.payment)),
							}} />
							{salary.payment && salary.payment.reimbursement && <RowPaymentGroup {...{
								title: 'Погашение задолженности',
								data: salary.payment.reimbursement,
							}} />}
						</Fragment>}
					</Fragment>}
					</tbody>
				</table>
				<p className='PageUserSalary__thanks'>Благодарю за отличную работу!</p>
				<p className={'PageUserSalary__footer'}>Со всеми вашими зарплатными карточками можно ознакомиться на
					страничке <Link to={url.user.salary.list}>Зарплата</Link>.</p>
			</div>);
		}

	}
}
