import React, { Component, Fragment } from 'react';
import { fmt } from '../../utils/format';

import './RowGroup.scss';

export interface RowGroupProps {
	title: string;
	amount?: number;
}

export interface RowGroupState {}

export class RowGroup extends Component<RowGroupProps, RowGroupState> {
	render() {
		const { title, amount } = this.props;
		return (
			<Fragment>
				<tr className="RowGroup">
					<td className="RowGroup__title">{title}</td>
					<td colSpan={2} className="RowGroup__amount">
						{fmt.rur(amount)}
					</td>
				</tr>
			</Fragment>
		);
	}
}
