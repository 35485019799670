import React, {Component} from 'react';
import { UserInfo } from '../UserInfo/UserInfo';
import { Course, User } from 'salary-shared';

export interface PageUserInfoProps {
  me: User;
  courses: Course[];
  onInit: () => void;
}

export interface PageUserInfoState {
}

export class PageUserInfo extends Component<PageUserInfoProps, PageUserInfoState> {

  componentDidMount() {
    this.props.onInit();
  }

  render() {
    const {me, courses} = this.props;
		return (<div>
			<h2>Информация о пользователе</h2>
      <UserInfo user={me} courses={courses}/>
		</div>);
	}
}