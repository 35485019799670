import Swagger from 'swagger-client';
import * as Logger from 'js-logger';
import { merge, isEmpty, isArray, has } from 'lodash';

import { Response } from './response';
import { troubleshoot } from '../utils/troubleshoot';
import { Errors } from '../models/Errors';
import { ActionUser } from '../reducers/ActionsUser';
import { store } from '../store';
import { User } from 'salary-shared';

const log = Logger.get('Api');

let cli: any = null;

export interface Trouble {
	title: string;
	message: string;
	details: string;
}

/**
 * Обработчик ошибок API, возвращает true, если нужно выбросить исключительную ситуацию Exception дальше.
 */
export type OnErrorHandler = (resp: any, response: any, trouble: Trouble) => boolean;

export interface ApiConfig {
	url: string;
	onGetSession: () => any;
	onErrorBadRequest: OnErrorHandler;
	onErrorUnauthenticated: OnErrorHandler;
	onErrorUnauthorized: OnErrorHandler;
	onErrorNotFound: OnErrorHandler;
	onErrorConflict: OnErrorHandler;
	onErrorServer: OnErrorHandler;
	onErrorDefault: OnErrorHandler;
}

const config: ApiConfig = {
	url: '',
	onGetSession: () => ({}),
	onErrorBadRequest: (resp: any, response: Response<any>, trouble: Trouble) => true,
	onErrorUnauthenticated: (resp: any, response: Response<any>, trouble: Trouble) => true,
	onErrorUnauthorized: (resp: any, response: Response<any>, trouble: Trouble) => true,
	onErrorNotFound: (resp: any, response: Response<any>, trouble: Trouble) => true,
	onErrorConflict: (resp: any, response: Response<any>, trouble: Trouble) => false,
	onErrorServer: (resp: any, response: Response<any>, trouble: Trouble) => true,
	onErrorDefault: (resp: any, response: Response<any>, trouble: Trouble) => true,
};

interface CliQuery {
	controller: string;
	method: string;
	params?: any;
}

function _handleFulfiled<T>(resp: any): Response<T> {
	log.debug('Fulfiled:', resp);
	const response = resp.obj || {};
	return {
		data: response.data,
		rawData: resp.data,
		msg: response.msg || [],
		// actions: [],
	};
}

function _handleRejected<T>(resp: any, query: CliQuery): Response<T> {
	log.debug('Rejected:', resp);
	const error = resp.errObj ? resp.errObj.message : 'Неизвестная ошибка';
	const title = error;
	const response = merge({}, resp && resp.obj);
	response.msg = response.msg || [];
	response.error = null;
	let throwError = true;

	const message =
		'Скопируйте содержимое поля "Подробности (кликните чтобы открыть)" и вышлите разработчикам для разбирательства.';
	let details;
	if (resp.status) {
		details = `Query: ${JSON.stringify(query, null, 2)}

Url: ${resp.url}

Response: ${resp.status} - ${error}

Server:
${resp.data}

Client:
${resp.errObj && resp.errObj.stack}
      `;
	} else {
		details = `Query: ${JSON.stringify(query, null, 2)}

Error: ${JSON.stringify(resp)}
      `;
	}

	details += '\n' + troubleshoot.report(config.onGetSession);

	const trouble = {
		title,
		message,
		details,
	};

	switch (resp.status) {
		case 400: // Bad Request
			throwError = config.onErrorBadRequest(resp, response, trouble);
			break;
		case 401: // Unauthenticated
			throwError = config.onErrorUnauthenticated(resp, response, trouble);
			break;
		case 403: // Unauthorized
			throwError = config.onErrorUnauthorized(resp, response, trouble);
			break;
		case 404: // Not found
			throwError = config.onErrorNotFound(resp, response, trouble);
			break;
		case 409: // Conflict
			throwError = config.onErrorConflict(resp, response, trouble);
			break;
		case 500: // Internal Server Error
		case 501: // Not Implemented
		case 502: // Bad Gateway
		case 503: // Service Unavailable
		case 504: // Gateway Timeout
		case 505: // HTTP Version Not Supported
			throwError = config.onErrorServer(resp, response, trouble);
			break;
		default:
			throwError = config.onErrorDefault(resp, response, trouble);
	}

	if (throwError) {
		throw response;
	} else {
		return response;
	}
}

function _handleResponse<T>(promise: Promise<any>, query: CliQuery): Promise<Response<T>> {
	return promise.then(
		resp => _handleFulfiled<T>(resp),
		resp => _handleRejected<T>(resp, query)
	);
}

function _cliRaw<T>(controller: string, method: string, params?: any): Promise<Response<T>> {
	log.debug(`${controller}.${method} params:`, params);

	return _handleResponse(cli.apis[controller][method](params), {
		controller,
		method,
		params,
	});
}

function _cli<T>(controller: string, method: string, params?: any): Promise<Response<T>> {
	return _cliRaw<T>(controller, method, params);
}

export const handle = (/**Promise*/ promise: Promise<any>) => {
	function _handleActions(kind: any) {
		return (resp: any) => {
			let result = [];
			if (resp && !isEmpty(resp.actions)) {
				result = resp.actions;
			} else if (isArray(resp)) {
				result = resp;
			} else if (isEmpty(resp)) {
				log.warn(`Empty ${kind} promise response:`, resp);
			}
			if (resp && !isEmpty(resp.msg)) {
				result = [
					...result,
					// ...config.onMakeMessageActions(resp)
				];
			}
			if (resp && Errors.DISCONNECTED === resp.error) {
				result = [
					...result,
					// NotificationsActions.load({
					// 	msg: [
					// 		Notifications.global.error(
					// 			'Соединение с сервером потеряно',
					// 			'Пожалуйста, проверьте состояние вашего подключения к интернету, обновите страницу при помощи клавиши F5 и попробуйте выполнить ваше действие ещё раз.'
					// 		)
					// 	]
					// })
				];
			}
			log.debug('handle', result);
			return result;
		};
	}

	if (!has(promise, 'then')) {
		promise = Promise.resolve(promise);
	}

	return promise.then(_handleActions('Fulfiled'), _handleActions('Rejected'));
};

export const Api = {
	init(url: string, token: string): Promise<any> {
		config.url = url;
		// Swagger.http.withCredentials = true;
		return new Swagger({
			url,
			usePromise: true,
			useJQuery: false,
			enableCookies: true,
			authorizations: {
				bearer: token,
			},
		})
			.then((client: any) => {
				cli = client;
				log.info('Клиент API (Swagger) успешно подключен.');
			})
			.then(() => {
				return Api.users.me().then(resp => {
					store.dispatch(ActionUser.login(resp.data as User));
				});
			});
	},
	users: {
		me: () => _cli('users', 'users_me', {}),
		find: (query: any) => _cli('users', 'users_find', { query }),
	},
	courses: {
		all: () => _cli('courses', 'courses_all', {}),
		forDate: (date: string) => _cli('courses', 'courses_for_date', { date }),
	},
	salaries: {
		byUserForPeriod: (user: string, start: string, end: string) =>
			_cli('salaries', 'salaries_by_user_for_period', {
				user,
				start,
				end,
			}),
		createForDate: (date: string) => _cli('salaries', 'salaries_create_for_date', { date }),
		forDate: (date: string) => _cli('salaries', 'salaries_for_date', { date }),
	},
	vacations: {
		byUserForPeriod: (user: string, start: string, end: string) =>
			_cli('vacations', 'vacations_by_user_for_period', {
				user,
				start,
				end,
			}),
		forYear: (year: string) =>
			_cli('vacations', 'vacations_by_year', {
				year,
			}),
	},
};
