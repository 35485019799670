import {Component, Fragment} from 'react';
import React from 'react';

import './RowDetails.scss';

export interface RowDetailsProps {
	caption?: string;
	data?: string;
	comment?: string;
}

export interface RowDetailsState {
}

export class RowDetails extends Component<RowDetailsProps, RowDetailsState> {
	render() {
		const {caption, data, comment} = this.props;
		return (<Fragment>
			<tr className="RowDetails">
				<td className="RowDetails__caption">{caption}</td>
				<td className="RowDetails__data">{data}</td>
				<td className="RowDetails__comment">{comment}</td>
			</tr>
		</Fragment>);
	}
}