import { connect } from 'react-redux';
import { Navbar as Cmp, NavbarProps } from '../components/Navbar/Navbar';
import { IState } from '../models/State';
import { DispatchFunc } from '../utils/redux';

export const Navbar = connect<{}, {}, NavbarProps, IState>(
  (state, ownProps) => {
    return {
      me: state.user.me,
    };
  },
  (dispatch: DispatchFunc, ownProps) => {
    return {};
  },
)(Cmp);