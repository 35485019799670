import React, { Component } from 'react';
import { Course, User } from 'salary-shared';
import { fmt } from '../../utils/format';
import { forEach, isEmpty, isNil, map, reverse, sortBy } from 'lodash';

export interface UserInfoProps {
	user: User;
	courses: Course[];
}

export interface UserInfoState {
}

export class UserInfo extends Component<UserInfoProps, UserInfoState> {
	render() {
		const { user, courses } = this.props;

		const userVisibleCourses = (courses: Course[]): Course[] => {
			const result: Course[] = [];
			forEach(sortBy(courses, 'date'), (course, idx) => {
				const visible = (course._id > user.start) && (isNil(user.end) || (user.end && course._id < user.end));
				if (visible && isEmpty(result) && (idx > 0) && (courses.length > idx - 1)) {
					result.push(courses[idx - 1]);
				}
				if (visible) {
					result.push(course);
				}
			});
			return result;
		};

		if (user) {
			return (<section>
					<h4>Общая информация</h4>
					<table>
						<tbody>
						<tr>
							<td>Начало работы</td>
							<td>{fmt.date(user.start)}</td>
						</tr>
						</tbody>
					</table>
					<h4>История изменения тарифа</h4>
					<table>
						<thead>
						<tr>
							<th>Месяц</th>
							<th>Тариф</th>
						</tr>
						</thead>
						<tbody>
						{map(reverse(sortBy(user.rate, 'date')), r => <tr key={`rate-${r.date}`}>
							<td className='text-center'>{fmt.month(r.date)}</td>
							<td className='text-right text-fixed'>{fmt.ueh(r.rate)}</td>
						</tr>)}
						</tbody>
					</table>
					<h4>История изменения курса У.Е.</h4>
					<table>
						<thead>
						<tr>
							<th>Месяц</th>
							<th>Курс</th>
						</tr>
						</thead>
						<tbody>
						{map(reverse(userVisibleCourses(courses)), c => <tr key={`rate-${c._id}`}>
							<td className='text-center'>{fmt.month(c._id)}</td>
							<td className='text-right text-fixed'>{fmt.rur(c.price)}</td>
						</tr>)}
						</tbody>
					</table>
					<h4>История изменения алгоритма расчёта зарплаты</h4>
					<table>
						<thead>
						<tr>
							<th>Месяц</th>
							<th>Алгоритм</th>
							<th>Комментарий</th>
						</tr>
						</thead>
						<tbody>
						{map(reverse(sortBy(user.salary, 'date')), r => <tr key={`mode-${r.date}`}>
							<td className='text-center'>{fmt.month(r.date)}</td>
							<td className=''>{fmt.salaryMode(r.mode)}</td>
							<td className=''>{fmt.salaryModeDesc(r.mode)}</td>
						</tr>)}
						</tbody>
					</table>
				</section>
			);
		} else {
			return (<div>

			</div>);
		}
	}
}
