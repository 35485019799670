import React, {Component, Fragment} from 'react';
import {fmt} from '../../utils/format';

import './RowSection.scss';

export interface RowSectionProps {
	title: string;
	amount: number;
}

export interface RowSectionState {
}

export class RowSection extends Component<RowSectionProps, RowSectionState> {
	render() {
		const {title, amount} = this.props;
		return (<Fragment>
			<tr className="RowSection">
				<td className="RowSection__title">{title}</td>
				<td colSpan={2} className="RowSection__amount">{fmt.rur(amount)}</td>
			</tr>
		</Fragment>);
	}
}