import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';

import './App.scss';

import { PageHome } from './containers/PageHome';
import { PageAdminVacations } from './containers/PageAdminVacations';
import { PageAdminSalary } from './containers/PageAdminSalary';
import { PageAdminUsers } from './containers/PageAdminUsers';
import { PageAdminUser } from './containers/PageAdminUser';
import { PageAdminUserSalary } from './containers/PageAdminUserSalary';
import { PageUserSalary } from './containers/PageUserSalary';
import { PageUserInfo } from './containers/PageUserInfo';
import { PageUserVacations } from './containers/PageUserVacations';
import { Navbar } from './containers/Navbar';
import { path } from './sitemap';
import { User } from 'salary-shared';
import { PageUserSalaryList } from './containers/PageUserSalaryList';

export interface AppProps {
	me: User;
}

export interface AppState {
}

export class App extends Component<AppProps, AppState> {
	render() {
		const { me } = this.props;
		return (
			<HashRouter>
				<div className='App'>
					<Navbar className='App__navbar' />

					<Route exact path={path.home} component={PageHome} />

					<Route exact path={path.user.info} component={PageUserInfo} />
					<Route exact path={path.user.vacations} component={PageUserVacations} />
					<Route exact path={path.user.salary.list} component={PageUserSalaryList} />
					<Route exact path={path.user.salary.month} component={PageUserSalary} />

					{me.isAdmin && <Route exact path={path.admin.vacations.current} component={PageAdminVacations} />}
					{me.isAdmin && <Route exact path={path.admin.vacations.year} component={PageAdminVacations} />}
					{me.isAdmin && <Route exact path={path.admin.salary.current} component={PageAdminSalary} />}
					{me.isAdmin && <Route exact path={path.admin.salary.month} component={PageAdminSalary} />}
					{me.isAdmin && <Route exact path={path.admin.users.home} component={PageAdminUsers} />}
					{me.isAdmin && <Route exact path={path.admin.users.current} component={PageAdminUser} />}
					{me.isAdmin && <Route exact path={path.admin.users.year} component={PageAdminUser} />}
					{me.isAdmin && <Route exact path={path.admin.users.salary} component={PageAdminUserSalary} />}
				</div>
			</HashRouter>
		);
	}
}
