import React, { Component, Fragment } from 'react';
import { MatchParams } from '../../utils/redux';
import { moment, SalaryMode, User, UserCalculator, Vacation } from 'salary-shared';
import { filter, map, partial } from 'lodash';
import { UserRow } from './UserRow';

import './PageAdminVacations.scss';
import { yearStart } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { url } from '../../sitemap';

export interface PageVacationsProps extends MatchParams<{ year: string }> {
	year: string;
	users: User[];
	vacations: Vacation[];
	onInit: () => void;
}

export interface PageVacationsState {
}

function _userCanHaveVacation(year: string, user?: User): boolean {
	const mode = UserCalculator.modeForDate(user?.salary ?? [], `${year}-12-31`);
	return SalaryMode.TIME_AND_MATERIAL !== mode;
}

export class PageAdminVacations extends Component<PageVacationsProps, PageVacationsState> {
	componentDidMount() {
		this.props.onInit();
	}

	componentDidUpdate(prevProps: PageVacationsProps) {
		if (prevProps.year !== this.props.year) {
			this.props.onInit();
		}
	}

	render() {
		const { year, users, vacations } = this.props;
		const us = filter(users, partial(_userCanHaveVacation, year));
		const cYear = moment().year();
		const years = Array.from({ length: cYear - yearStart + 1 }, (v, i) => `${yearStart + i}`);
		return (
			<div className='PageAdminVacations'>
				<h2>Отпуска {year} года</h2>
				<table className='PageAdminVacations__table'>
					<thead>
					<tr>
						<th className='PageAdminVacations__th--main' rowSpan={2}>Сотрудник</th>
						<th className='PageAdminVacations__th--main' rowSpan={2}>Начало</th>
						<th className='PageAdminVacations__th--main' rowSpan={2}>Стаж (м)</th>
						<th className='PageAdminVacations__th--main' rowSpan={2}>Отпуск (д)</th>
						<th className='PageAdminVacations__th--main' colSpan={13}>Отгуляно (д)</th>
						<th className='PageAdminVacations__th--main' rowSpan={2}>Остаток (д)</th>
					</tr>
					<tr>
						<th className='PageAdminVacations__th--month'>Январь</th>
						<th className='PageAdminVacations__th--month'>Февраль</th>
						<th className='PageAdminVacations__th--month'>Март</th>
						<th className='PageAdminVacations__th--month'>Апрель</th>
						<th className='PageAdminVacations__th--month'>Май</th>
						<th className='PageAdminVacations__th--month'>Июнь</th>
						<th className='PageAdminVacations__th--month'>Июль</th>
						<th className='PageAdminVacations__th--month'>Август</th>
						<th className='PageAdminVacations__th--month'>Сентябрь</th>
						<th className='PageAdminVacations__th--month'>Октябрь</th>
						<th className='PageAdminVacations__th--month'>Ноябрь</th>
						<th className='PageAdminVacations__th--month'>Декабрь</th>
						<th className='PageAdminVacations__th--main'>Итого</th>
					</tr>
					</thead>
					<tbody>
					{map(us, u => (
						<UserRow
							{...{
								key: u!._id,
								year,
								user: u!,
								vacations: filter(vacations, v => v.user === u!._id),
							}}
						/>
					))}
					</tbody>
				</table>
				<h2>Другие года</h2>
				{years.map(year => <Fragment key={year}><Link to={url.admin.vacations.year(year)}>{year}</Link>, </Fragment>)}
			</div>
		);
	}
}
