import { AppAction } from './AppAction';
import { schema } from 'normalizr';

export class ActionEntityEmpty extends AppAction {}

export class ActionEntityClear extends AppAction<string> {}

export interface ActionEntityValuesPayload {
	schema: string;
	data: any | any[];
}

export interface ActionEntityIdPayload {
	schema: string;
	id: string | number | string[] | number[];
}

export class ActionEntityApply extends AppAction<ActionEntityValuesPayload> {}

export class ActionEntityLoad extends AppAction<ActionEntityValuesPayload> {}

export class ActionEntityRemove extends AppAction<ActionEntityIdPayload> {}

export interface ActionEntityPredicatePayload {
	schema: string;
	pred: (obj: any) => boolean;
}

export class ActionEntityRemoveBy extends AppAction<ActionEntityPredicatePayload> {}

export const ActionEntity = {
	empty: () => new ActionEntityEmpty(),
	clear: (s: schema.Entity) => new ActionEntityClear(s.key),
	apply: (s: schema.Entity, data: any | any[]) =>
		new ActionEntityApply({
			schema: s.key,
			data,
		}),
	load: (s: schema.Entity, data: any | any[]) =>
		new ActionEntityLoad({
			schema: s.key,
			data,
		}),
	remove: (s: schema.Entity, id: string | number | string[] | number[]) =>
		new ActionEntityRemove({
			schema: s.key,
			id,
		}),
	removeBy: (s: schema.Entity, pred: (obj: any) => boolean) =>
		new ActionEntityRemoveBy({
			schema: s.key,
			pred,
		}),
	loadResp: (s: schema.Entity) => (resp: any) => ActionEntity.load(s, resp.data),
};
