import { ReducerClass, Action, Immutable } from 'reducer-class';
import { normalize, schema } from 'normalizr';
import { Dictionary, each, has } from 'lodash';

import {
	ActionEntityEmpty,
	ActionEntityRemove,
	ActionEntityLoad,
	ActionEntityClear,
	ActionEntityApply,
	ActionEntityRemoveBy,
} from './ActionsEntity';
import { forceArray } from '../utils/forceArray';
import { Schema } from '../models/Schema';

export type EntityState = Dictionary<any>;

export class ReducerEntity extends ReducerClass<EntityState> {
	initialState = {};

	@Action(ActionEntityEmpty)
	empty(state: Immutable<EntityState>, action: ActionEntityEmpty) {
		return this.initialState;
	}

	@Action(ActionEntityRemove)
	remove(state: Immutable<EntityState>, draft: EntityState, action: ActionEntityRemove) {
		const { schema, id } = action.payload;
		const ids = forceArray(id);
		if (!has(draft, schema)) {
			draft[schema] = {};
		}
		const collection = draft[schema];
		each(ids, i => {
			delete collection[i];
		});
		return undefined;
	}

	@Action(ActionEntityRemoveBy)
	removeBy(state: Immutable<EntityState>, draft: EntityState, action: ActionEntityRemoveBy) {
		const { schema, pred } = action.payload;
		const collection = draft[schema];
		each(collection, (value, key) => {
			if (pred(value)) {
				delete collection[key];
			}
		});
		return undefined;
	}

	@Action(ActionEntityLoad)
	load(state: Immutable<EntityState>, draft: EntityState, action: ActionEntityLoad) {
		const s = action.payload.schema;
		const data = forceArray(action.payload.data);
		const entities = normalize(data, new schema.Array(Schema[s]));
		console.log(entities);
		each(entities.entities, (value, key) => {
			if (!has(draft, key)) {
				draft[key] = {};
			}
			const collection = draft[key];
			each(value, (vValue, vKey) => {
				collection[vKey] = vValue;
			});
		});
		return undefined;
	}

	@Action(ActionEntityApply)
	apply(state: Immutable<EntityState>, draft: EntityState, action: ActionEntityApply) {
		const s = action.payload.schema;
		const data = forceArray(action.payload.data);
		const entities = normalize(data, new schema.Array(Schema[s]));
		each(entities.entities, (value, key) => {
			if (!has(draft, key)) {
				draft[key] = {};
			}
			const collection = draft[key];
			each(Object.keys(collection), vKey => {
				if (!has(entities.entities, vKey)) {
					delete collection[vKey];
				}
			});
			each(entities.entities, (vValue, vKey) => {
				collection[vKey] = vValue;
			});
		});

		return undefined;
	}

	@Action(ActionEntityClear)
	clear(state: Immutable<EntityState>, draft: EntityState, action: ActionEntityClear) {
		draft[action.payload] = {};
		return undefined;
	}
}
