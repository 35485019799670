import React, { Component, Fragment } from 'react';

import { fmt } from '../../utils/format';

import { monthWorkHours, Salary, SalarySourceCalculator, User } from 'salary-shared';
import { RowDetails } from './RowDetails';

export interface UserSalaryInsured2024Props {
	user: User;
	salary: Salary;
	rate: number;
	course: number;
}

export interface UserSalaryInsured2024State {
}

export class UserSalaryInsured2024 extends Component<
	UserSalaryInsured2024Props,
	UserSalaryInsured2024State
> {
	render() {
		const { salary, rate, course } = this.props;

		const hours = SalarySourceCalculator.totalHours(salary.src);

		const fullHours = Math.min(hours, monthWorkHours);
		const halvedHours = Math.max(hours - monthWorkHours, 0);

		const hasVacation = (salary.src.vacation && salary.src.vacation > 0) || false;

		return (
			<Fragment>
				<RowDetails
					{...{
						caption: 'Страховка',
						data: fmt.rur(salary.wage.insurance),
						comment: `= прогноз ${fmt.hours(salary.src.prognosis)} * тариф ${fmt.ueh(rate)} * курс ${fmt.rur(
							course,
						)} * 0,5`,
					}}
				/>
				<RowDetails
					{...{
						caption: 'Премия за стаж',
						data: fmt.rur(salary.wage.seniority),
						comment: `= стаж (${fmt.seniorityH(salary.src.seniority ?? 0)}) ${
							fmt.seniorityHM(salary.src.seniority ?? 0, monthWorkHours)
						} * тариф ${fmt.ueh(rate)} * курс ${fmt.rur(course)} * 0,125`,
					}}
				/>
				<RowDetails
					{...{
						caption: 'Премия за выработку',
						data: fmt.rur(salary.wage.work),
						comment: halvedHours
							? `= выработка (${fmt.hours(fullHours)} + ${fmt.hours(halvedHours)} * 0,5) * тариф ${fmt.ueh(
								rate,
							)} * курс ${fmt.rur(course)} * 0,5`
							: `= выработка ${fmt.hours(fullHours)} * тариф ${fmt.ueh(rate)} * курс ${fmt.rur(course)} * 0,5`,
					}}
				/>
				{hasVacation && (
					<RowDetails
						{...{
							caption: 'Выработка',
							data: fmt.hours(hours),
							comment: `= работа ${fmt.hours(salary.src.worked)} + отпуск (${fmt.hours(
								salary.src.prognosis,
							)} / 28 д.) * ${fmt.days(salary.src.vacation!)}`,
						}}
					/>
				)}
			</Fragment>
		);
	}
}
