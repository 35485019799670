import {Component, Fragment} from 'react';
import {Payment, SalaryPaymentCalculator} from 'salary-shared';
import {map} from 'lodash';
import {fmt} from '../../utils/format';
import React from 'react';
import {RowDetails} from './RowDetails';
import {RowGroup} from './RowGroup';

export interface RowPaymentGroupProps {
	title: string;
	data: Payment[];
}

export interface RowPaymentGroupState {
}

export class RowPaymentGroup extends Component<RowPaymentGroupProps, RowPaymentGroupState> {
	render() {
		const {title, data} = this.props;
		return (<Fragment>
			<RowGroup {...{
				title,
				amount: SalaryPaymentCalculator.sum(data)
			}}/>
			{map(data, (d, idx) => <RowDetails {...{
				key: `payment-${idx}`,
				caption: fmt.date(d.date),
				data: fmt.rur(d.amount),
				comment: d.comment
			}}/>)}
		</Fragment>);
	}
}