import React, { Component } from 'react';
import { SalaryMode, User, UserCalculator } from 'salary-shared';
import { url } from '../../sitemap';
import { Link } from 'react-router-dom';
import { currentMonth } from '../../utils/utils';
import './PageHome.scss';

export interface PageHomeProps {
	me?: User;
	onInit: () => void;
}

export interface PageHomeState {
}

export class PageHome extends Component<PageHomeProps, PageHomeState> {
	componentDidMount() {
		this.props.onInit();
	}

	render() {
		const { me } = this.props;
		let hasVacations = false;
		if (me) {
			hasVacations = SalaryMode.TIME_AND_MATERIAL !== UserCalculator.modeForDate(me.salary, currentMonth);
		}
		return (
			<div className={'PageHome'}>
				<h2>Начало</h2>
				<ul>
					<li><Link to={url.user.info}>Информация</Link> &mdash; здесь можно найти общую информацию о сотруднике, его
						историю изменений
						тарифа, курса У.Е. и так далее
					</li>
					{hasVacations && (
						<li><Link to={url.user.vacations}>Отпуска</Link> &mdash; здесь информация об отпусках этого года, и прошлых
							лет</li>
					)}
					<li><Link to={url.user.salary.list}>Зарплата</Link> &mdash; тут зарплатные карточки</li>
				</ul>
				<p>&nbsp;</p>
				<p className={'PageHome__footer'}>
					Подробнее о принципах расчёта заработной платы, отпусках и тому подобном можно узнать на страничке <a
					href={'https://prj.hitsoft-it.com/projects/h/wiki/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D1%8B_%D1%82%D1%80%D1%83%D0%B4%D0%B0'}
					target={'_blank'} rel={'noopener noreferrer'}>Политика оплаты труда</a>.
				</p>
			</div>
		);
	}
}
