import { connect } from 'react-redux';
import { PageAdminUsers as Page, PageUsersProps } from '../components/PageAdminUsers/PageAdminUsers';
import { IState, State } from '../models/State';
import { Api } from '../api/Api';
import { Schema } from '../models/Schema';
import { ActionEntity } from '../reducers/ActionsEntity';

export const PageAdminUsers = connect<{}, {}, PageUsersProps, IState>(
	(state, ownProps) => ({
		users: State.model.users.working(state),
	}),
	(dispatch, ownProps) => ({
		onInit: () =>
			Api.users
				.find({})
				.then(ActionEntity.loadResp(Schema.users!))
				.then(dispatch),
	}),
)(Page);
