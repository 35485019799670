import { connect } from 'react-redux';

import { concat, filter, partial } from 'lodash';

import { PageAdminUser as Page, PageAdminUserProps } from '../components/PageAdminUser/PageAdminUser';
import { IState, State } from '../models/State';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { moment } from 'salary-shared';
import { DispatchFunc } from '../utils/redux';
import { currentMonth } from '../utils/utils';

const _year = (y?: string) => y || moment().year();

const pInDateRange = (startDate: string, endDate: string, o: { date: string }) =>
	o.date >= startDate && o.date <= endDate;

export const PageAdminUser = connect<{}, {}, PageAdminUserProps, IState>(
	(state, ownProps) => {
		const userId = ownProps.match.params.id;
		const year = _year(ownProps.match.params.year);

		const yearStart = moment(`${year}-01`).add(-1, 'years').format('YYYY-MM');
		const yearEnd = `${year}-12`;

		return {
			user: State.entity.users(state)[userId],
			salaries: filter(State.model.salaries.byUser(state)[userId], partial(pInDateRange, yearStart, yearEnd)),
			vacations: filter(State.model.vacations.byUser(state)[userId], partial(pInDateRange, yearStart, yearEnd)),
			courses: State.entity.courses(state),
		};
	},
	(dispatch: DispatchFunc, ownProps) => {
		const userId = ownProps.match.params.id;
		const year = _year(ownProps.match.params.year);

		const yearStart = `${year}-01`;
		const yearEnd = `${year}-12`;

		return {
			onInit: () =>
				Promise.all([
					Api.users.find({
						_id: userId,
					}),
					Api.salaries.byUserForPeriod(userId, yearStart, yearEnd),
					Api.salaries.createForDate(currentMonth),
					Api.vacations.byUserForPeriod(userId, yearStart, yearEnd),
					Api.courses.all(),
				])
					.then(resps =>
						concat(
							ActionEntity.loadResp(Schema.users!)(resps[0]),
							ActionEntity.loadResp(Schema.salaries!)(resps[1]),
							ActionEntity.loadResp(Schema.salaries!)(resps[2]),
							ActionEntity.loadResp(Schema.vacations!)(resps[3]),
							ActionEntity.loadResp(Schema.courses!)(resps[4]),
						),
					)
					.then(dispatch),
		};
	},
)(Page);
