import React, { Component } from 'react';
import { moment, Salary, SalarySourceCalculator, User, Vacation, VacationCalculator } from 'salary-shared';
import { concat, filter, map, reverse, sortBy, uniq } from 'lodash';
import { YearRow } from './YearRow';
import './PageUserVacations.scss';
import { Link } from 'react-router-dom';
import { url } from '../../sitemap';
import { fmt } from '../../utils/format';

export interface PageUserVacationsProps {
	me: User;
	vacations: Vacation[];
	salary: Salary;
	onInit: () => void;
}

export interface PageUserVacationsState {
}

export class PageUserVacations extends Component<PageUserVacationsProps, PageUserVacationsState> {

	componentDidMount() {
		this.props.onInit();
	}

	render() {
		const { me, vacations, salary } = this.props;
		const years: string[] = reverse(
			sortBy(
				uniq(
					concat(
						map(vacations, v => moment(v.date).format('YYYY')),
						[moment().format('YYYY')],
					),
				),
			),
		);

		const vacationsForYear = (year: string): Vacation[] => {
			return filter(vacations, v => (v.user === me._id && moment(v.date).format('YYYY') === year));
		};

		return <div className={'PageUserVacations'}>
			<h2>Отпуска</h2>

			{salary &&
				<table>
					<tbody>
					<tr>
						<th className={'PageUserVacations__td-label'}>Текущий
							расчётный месяц
						</th>
						<td className={'PageUserVacations__td-value'}><Link
							to={url.user.salary.month(salary.date)}>{fmt.month(salary.date)}</Link></td>
					</tr>
					<tr>
						<th className={'PageUserVacations__td-label'}>Прогноз</th>
						<td className={'PageUserVacations__td-value'}>{fmt.hours(salary.src.prognosis)}</td>
					</tr>
					<tr>
						<th className={'PageUserVacations__td-label'}>Выработка</th>
						<td className={'PageUserVacations__td-value'}>{fmt.hours(salary.src.worked)}</td>
					</tr>
					<tr>
						<th className={'PageUserVacations__td-label'}>Отпускные часы</th>
						<td
							className={'PageUserVacations__td-value'}>{fmt.hours(SalarySourceCalculator.vacationHours(salary.src))}</td>
					</tr>
					<tr>
						<td colSpan={2} className={'PageUserVacations__td-delimiter'}></td>
					</tr>
					<tr>
						<th className={'PageUserVacations__td-label'}>Часов до прогноза</th>
						<td
							className={'PageUserVacations__td-value'}>{fmt.hours(SalarySourceCalculator.hoursToPrognosis(salary.src))}</td>
					</tr>
					<tr>
						<th className={'PageUserVacations__td-label'}>Можно добавить отпускных дней</th>
						<td className={'PageUserVacations__td-value'}>
							{fmt.days(VacationCalculator.days(
								SalarySourceCalculator.hoursToPrognosis(salary.src),
								salary.src.prognosis))}
							<br />
							{fmt.hours(VacationCalculator.hours(
								VacationCalculator.days(SalarySourceCalculator.hoursToPrognosis(salary.src), salary.src.prognosis),
								salary.src.prognosis))}
						</td>
					</tr>
					</tbody>
				</table>}

			<table>
				<thead>
				<tr>
					<th rowSpan={2}>Год</th>
					<th rowSpan={2}>Стаж (м)</th>
					<th rowSpan={2}>Отпуск (д)</th>
					<th colSpan={13}>Отгуляно (д)</th>
					<th rowSpan={2}>Остаток (д)</th>
				</tr>
				<tr>
					<th>Январь</th>
					<th>Февраль</th>
					<th>Март</th>
					<th>Апрель</th>
					<th>Май</th>
					<th>Июнь</th>
					<th>Июль</th>
					<th>Август</th>
					<th>Сентябрь</th>
					<th>Октябрь</th>
					<th>Ноябрь</th>
					<th>Декабрь</th>
					<th>Итого</th>
				</tr>
				</thead>
				<tbody>
				{map(years, (year) => <YearRow key={year} year={year} user={me} vacations={vacationsForYear(year)} />)}
				</tbody>
			</table>
		</div>;
	}
}
