import React, { Component, Fragment } from 'react';
import { map, sortBy } from 'lodash';
import { Link } from 'react-router-dom';

import {
	Course,
	Salary,
	SalaryCalculator,
	SalaryMode,
	SalaryPaymentCalculator,
	SalarySourceCalculator,
	SalaryWageCalculator,
	User,
	Vacation,
} from 'salary-shared';
import { fmt } from '../../utils/format';

import './PageAdminUser.scss';
import { url } from '../../sitemap';
import { MatchParams } from '../../utils/redux';
import { UserInfo } from '../UserInfo/UserInfo';

export interface PageAdminUserProps extends MatchParams<{ id: string, year: string }> {
	user: User;
	salaries: Salary[],
	vacations: Vacation[],
	courses: Course[]
	onInit: () => void;
}

export interface PageAdminUserState {
}

function noTsr(mode: SalaryMode, value: string): string {
	return SalaryMode.TSR !== mode ? value : '';
}

export class PageAdminUser extends Component<PageAdminUserProps, PageAdminUserState> {

	componentDidMount() {
		this.props.onInit();
	}

	render() {
		const { user, salaries, vacations, courses } = this.props;
		const sal = sortBy(salaries, 'date');

		return (<div className='PageAdminUser'>
			{user && <Fragment>
				<h2>{user.name}</h2>
				<UserInfo user={user} courses={courses} />
				<section>
					<h4>Отпуск</h4>
					<table>
						<thead>
						<tr>
							<th>Месяц</th>
							<th>Дни</th>
						</tr>
						</thead>
						<tbody>
						{map(vacations, v => <tr key={`vacation-${v.date}`}>
							<td className='text-center'>{fmt.month(v.date)}</td>
							<td className='text-right text-fixed'>{v.days}</td>
						</tr>)}
						</tbody>
					</table>
				</section>
				<section>
					<h4>Зарплата</h4>
					<table>
						<thead>
						<tr>
							<th>Месяц</th>
							<th>Алгоритм</th>
							<th>Выработка</th>
							<th>Отпуск</th>
							<th>Начислено</th>
							<th>Выплачено</th>
							<th>Задолженность</th>
						</tr>
						</thead>
						<tbody>
						{map(sal, s => <tr key={s._id.toString()}>
							<td className='text-center'><Link to={url.admin.users.salary(user._id, s.date)}>{fmt.month(s.date)}</Link>
							</td>
							<td>{fmt.salaryMode(s.mode)}</td>
							<td className='text-right text-fixed'>{fmt.hours(s.src.worked)}</td>
							<td
								className='text-right text-fixed'>{noTsr(s.mode, fmt.hours(SalarySourceCalculator.vacationHours(s.src)))}</td>
							<td className='text-right text-fixed'>{noTsr(s.mode, fmt.rur(SalaryWageCalculator.total(s.wage)))}</td>
							<td
								className='text-right text-fixed'>{noTsr(s.mode, fmt.rur(SalaryPaymentCalculator.total(s.payment)))}</td>
							<td className='text-right text-fixed'>{noTsr(s.mode, fmt.rur(SalaryCalculator.arrearsAfter(s)))}</td>
						</tr>)}
						</tbody>
					</table>
				</section>
			</Fragment>}
			{!user && <h2>Загружается...</h2>}
		</div>);
	}
}
