"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestSalariesCreate = void 0;
var api_model_property_decorator_1 = require("@nestjs/swagger/dist/decorators/api-model-property.decorator");
var RequestSalariesCreate = /** @class */ (function () {
    function RequestSalariesCreate() {
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            type: Boolean,
            description: 'Принудительно пересчитать даже архивные записи',
            required: false,
            default: false
        }),
        __metadata("design:type", Boolean)
    ], RequestSalariesCreate.prototype, "forceArchive", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            type: Number,
            description: 'Наличие денег в кассе (сумма в рублях)',
            required: false,
            default: 0
        }),
        __metadata("design:type", Number)
    ], RequestSalariesCreate.prototype, "cash", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            type: String,
            description: 'Дата наличного платежа в формате `YYYY-MM-DD`',
            required: false
        }),
        __metadata("design:type", String)
    ], RequestSalariesCreate.prototype, "paymentDate", void 0);
    return RequestSalariesCreate;
}());
exports.RequestSalariesCreate = RequestSalariesCreate;
