"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseCourses = exports.ResponseVacations = exports.ResponseSalaries = exports.ResponseCourse = exports.AbstractResponse = exports.Message = exports.MessageLocation = exports.MessageKind = void 0;
var api_model_property_decorator_1 = require("@nestjs/swagger/dist/decorators/api-model-property.decorator");
var model_1 = require("./model");
var MessageKind;
(function (MessageKind) {
    /**
     * Информационное сообщение
     */
    MessageKind["INFO"] = "INFO";
    /**
     * Предупреждение
     */
    MessageKind["WARNING"] = "WARNING";
    /**
     * Успешное завершение действия
     */
    MessageKind["SUCCESS"] = "SUCCESS";
    /**
     * Сообщение об ошибке
     */
    MessageKind["ERROR"] = "ERROR";
    /**
     * Отладочное сообщение, показывать только в режиме разработки
     */
    MessageKind["DEBUG"] = "DEBUG";
})(MessageKind = exports.MessageKind || (exports.MessageKind = {}));
/**
 * Способ отображения (адресат) сообщения
 */
var MessageLocation;
(function (MessageLocation) {
    /**
     * Сообщение адресовано конкретному полю формы. Название поля указано в Message.context
     * @see Message.context
     */
    MessageLocation["FIELD"] = "FIELD";
    /**
     * Сообщение адресовано форме ввода
     */
    MessageLocation["FORM"] = "FORM";
    /**
     * Сообщение адресовано всему приложению
     */
    MessageLocation["GLOBAL"] = "GLOBAL";
    /**
     * Сообщение адресовано всему приложению, но в форме неблокирующего всплывающего сообщения
     */
    MessageLocation["TOAST"] = "TOAST";
})(MessageLocation = exports.MessageLocation || (exports.MessageLocation = {}));
/**
 * Информационное сообщение от сервера (валидация, ошибки, отладка)
 */
var Message = /** @class */ (function () {
    function Message() {
        this.kind = MessageKind.DEBUG;
        this.message = '';
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Расположение сообщения',
            enum: [MessageLocation.FIELD, MessageLocation.FORM, MessageLocation.GLOBAL, MessageLocation.TOAST],
            required: false
        }),
        __metadata("design:type", String)
    ], Message.prototype, "location", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Тип сообщения',
            enum: [MessageKind.DEBUG, MessageKind.ERROR, MessageKind.SUCCESS, MessageKind.WARNING]
        }),
        __metadata("design:type", String)
    ], Message.prototype, "kind", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Контекст сообщения',
            required: false
        }),
        __metadata("design:type", String)
    ], Message.prototype, "context", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Заголовок сообщения',
            required: false
        }),
        __metadata("design:type", String)
    ], Message.prototype, "title", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Текст сообщения',
        }),
        __metadata("design:type", String)
    ], Message.prototype, "message", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Количество повторов',
            required: false
        }),
        __metadata("design:type", Number)
    ], Message.prototype, "count", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дополнительная информация',
            required: false
        }),
        __metadata("design:type", String)
    ], Message.prototype, "details", void 0);
    return Message;
}());
exports.Message = Message;
var AbstractResponse = /** @class */ (function () {
    function AbstractResponse() {
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Сопутствующие информационные сообщения от сервера (валидация, ошибки, отладка)',
            isArray: true,
            type: Message
        }),
        __metadata("design:type", Array)
    ], AbstractResponse.prototype, "msg", void 0);
    return AbstractResponse;
}());
exports.AbstractResponse = AbstractResponse;
var ResponseCourse = /** @class */ (function (_super) {
    __extends(ResponseCourse, _super);
    function ResponseCourse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Полезная нагрузка, данные, полученные от сервера',
            type: model_1.Course
        }),
        __metadata("design:type", model_1.Course)
    ], ResponseCourse.prototype, "data", void 0);
    return ResponseCourse;
}(AbstractResponse));
exports.ResponseCourse = ResponseCourse;
var ResponseSalaries = /** @class */ (function (_super) {
    __extends(ResponseSalaries, _super);
    function ResponseSalaries() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Полезная нагрузка, данные, полученные от сервера',
            isArray: true,
            type: model_1.Salary
        }),
        __metadata("design:type", Array)
    ], ResponseSalaries.prototype, "data", void 0);
    return ResponseSalaries;
}(AbstractResponse));
exports.ResponseSalaries = ResponseSalaries;
var ResponseVacations = /** @class */ (function (_super) {
    __extends(ResponseVacations, _super);
    function ResponseVacations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Полезная нагрузка, данные, полученные от сервера',
            isArray: true,
            type: model_1.Vacation
        }),
        __metadata("design:type", Array)
    ], ResponseVacations.prototype, "data", void 0);
    return ResponseVacations;
}(AbstractResponse));
exports.ResponseVacations = ResponseVacations;
var ResponseCourses = /** @class */ (function (_super) {
    __extends(ResponseCourses, _super);
    function ResponseCourses() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Полезная нагрузка, данные, полученные от сервера',
            isArray: true,
            type: model_1.Course
        }),
        __metadata("design:type", Array)
    ], ResponseCourses.prototype, "data", void 0);
    return ResponseCourses;
}(AbstractResponse));
exports.ResponseCourses = ResponseCourses;
