import { connect } from 'react-redux';
import { PageAdminSalary as Page, PageAdminSalaryProps } from '../components/PageAdminSalary/PageAdminSalary';
import { IState, State } from '../models/State';
import { Course } from 'salary-shared';
import { concat, Dictionary, filter, head, map, reverse, sortBy } from 'lodash';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { DispatchFunc } from '../utils/redux';
import { month } from '../utils/utils';

const courseForDate = (courses: Dictionary<Course>, date: string) => {
	let result: any = filter(courses, s => s._id <= date);
	result = sortBy(result, '_id');
	result = reverse(result);
	result = map(result, 'price');
	result = head(result);
	return result;
};

export const PageAdminSalary = connect<{}, {}, PageAdminSalaryProps, IState>(
	(state, ownProps) => {
		const mon = month(ownProps.match.params.month);

		return {
			month: mon,
			users: State.entity.users(state),
			salaries: sortBy(State.model.salaries.byDate(state)[mon], 'user'),
			dateCourse: courseForDate(State.entity.courses(state), mon),
		};
	},
	(dispatch: DispatchFunc, ownProps) => {
		const mon = month(ownProps.match.params.month);

		return {
			onInit: () =>
				Promise.all([Api.users.find({}), Api.salaries.createForDate(mon), Api.courses.forDate(mon)])
					.then(resps =>
						concat(
							ActionEntity.loadResp(Schema.users)(resps[0]),
							ActionEntity.loadResp(Schema.salaries)(resps[1]),
							ActionEntity.loadResp(Schema.courses)(resps[2]),
						),
					)
					.then(dispatch),
		};
	},
)(Page);
