import React, {Component} from 'react';
import {Salary, User} from 'salary-shared';
import {fmt} from '../../utils/format';
import {RowDetails} from './RowDetails';

export interface UserSalaryTimeAndMaterialProps {
	user: User;
	salary: Salary;
	rate: number;
	course: number;
}

export interface UserSalaryTimeAndMaterialState {
}

export class UserSalaryTimeAndMaterial extends Component<UserSalaryTimeAndMaterialProps, UserSalaryTimeAndMaterialState> {
	render() {
		const {salary, rate, course} = this.props;
		return (<RowDetails {...{
			caption: 'Зарплата',
			data: fmt.rur(salary.wage.work),
			comment: `= ${fmt.hours(salary.src.worked)} * ${fmt.ueh(rate)} * ${fmt.rur(course)}`
		}}/>);
	}
}