"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Salary = exports.SalaryAccount = exports.SalaryPayment = exports.SalaryWage = exports.SalaryBonus = exports.SalarySource = exports.WorkSheetProject = exports.SalaryState = exports.PaymentsGroup = exports.Payment = exports.Vacation = exports.Course = exports.User = exports.UserRate = exports.UserSalary = exports.SalaryMode = void 0;
var bson_1 = require("bson");
var api_model_property_decorator_1 = require("@nestjs/swagger/dist/decorators/api-model-property.decorator");
/**
 * Режим насчёта заработной платы
 */
var SalaryMode;
(function (SalaryMode) {
    /**
     * Старые данные по учёту времени
     */
    SalaryMode["TSR"] = "TSR";
    /**
     * Упрощённая формула, сколько отработал — столько и получил
     */
    SalaryMode["TIME_AND_MATERIAL"] = "TIME_AND_MATERIAL";
    /**
     * Полная формула учитывающая страховку стажа работы
     */
    SalaryMode["INSURED_SENIORITY"] = "INSURED_SENIORITY";
    /**
     * Базовая новая формула учитывающая страховку стажа работы и отпуска для любых объёмов выработки
     */
    SalaryMode["INSURED_2024"] = "INSURED_2024";
})(SalaryMode = exports.SalaryMode || (exports.SalaryMode = {}));
var UserSalary = /** @class */ (function () {
    function UserSalary() {
        this.date = '';
        this.mode = SalaryMode.INSURED_2024;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата начала действия режима в формате `YYYY-MM`',
        }),
        __metadata("design:type", String)
    ], UserSalary.prototype, "date", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Действующий режим',
            enum: [SalaryMode.TSR, SalaryMode.INSURED_SENIORITY, SalaryMode.TIME_AND_MATERIAL, SalaryMode.INSURED_2024],
        }),
        __metadata("design:type", String)
    ], UserSalary.prototype, "mode", void 0);
    return UserSalary;
}());
exports.UserSalary = UserSalary;
var UserRate = /** @class */ (function () {
    function UserRate() {
        this.date = '';
        this.rate = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата начала действия рейта в формате `YYYY-MM`',
        }),
        __metadata("design:type", String)
    ], UserRate.prototype, "date", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Рейт в условных копейках',
        }),
        __metadata("design:type", Number)
    ], UserRate.prototype, "rate", void 0);
    return UserRate;
}());
exports.UserRate = UserRate;
/**
 * Сотрудник
 */
var User = /** @class */ (function () {
    function User() {
        this._id = '';
        this.name = '';
        this.start = '';
        this.end = '';
        this.initialPrognosis = 0;
        this.salary = [];
        this.rate = [];
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Идентификатор (логин в Jira и прочих системах)',
        }),
        __metadata("design:type", String)
    ], User.prototype, "_id", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Имя пользователя',
        }),
        __metadata("design:type", String)
    ], User.prototype, "name", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата начала работы в формате (YYYY-MM-DD)',
        }),
        __metadata("design:type", String)
    ], User.prototype, "start", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата окончания работы в формате (YYYY-MM-DD)',
            required: false,
        }),
        __metadata("design:type", String)
    ], User.prototype, "end", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Начальный прогноз в часах, испольуется для вычисления прогноза на месяц, когда история зарплат пуста',
        }),
        __metadata("design:type", Number)
    ], User.prototype, "initialPrognosis", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Режим расчёта заработной платы',
            isArray: true,
            type: UserSalary,
        }),
        __metadata("design:type", Array)
    ], User.prototype, "salary", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Рейт',
            isArray: true,
            type: UserRate,
        }),
        __metadata("design:type", Array)
    ], User.prototype, "rate", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Администратор?',
            required: false,
        }),
        __metadata("design:type", Boolean)
    ], User.prototype, "isAdmin", void 0);
    return User;
}());
exports.User = User;
/**
 * Курс условной единицы расчёта
 */
var Course = /** @class */ (function () {
    function Course() {
        this._id = '';
        this.price = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата месяца курса в формате `YYYY-MM`',
        }),
        __metadata("design:type", String)
    ], Course.prototype, "_id", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Цена условной единицы в копейках',
        }),
        __metadata("design:type", Number)
    ], Course.prototype, "price", void 0);
    return Course;
}());
exports.Course = Course;
/**
 * Информация об отпусках
 */
var Vacation = /** @class */ (function () {
    function Vacation() {
        /**
         * Идентификатор записи. Не используется, рабочий идентификатор - это пара полей user + date
         */
        this._id = new bson_1.ObjectId();
        this.user = '';
        this.date = '';
        this.days = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Сотрудник использовавший отпуск',
        }),
        __metadata("design:type", String)
    ], Vacation.prototype, "user", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата использованного отпуска в формате `YYYY-MM`',
        }),
        __metadata("design:type", String)
    ], Vacation.prototype, "date", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Количество дней использованных от отпуска',
        }),
        __metadata("design:type", Number)
    ], Vacation.prototype, "days", void 0);
    return Vacation;
}());
exports.Vacation = Vacation;
/**
 * Платёж
 */
var Payment = /** @class */ (function () {
    function Payment() {
        this.date = '';
        this.amount = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата платежа в формате `YYYY-MM-DD`',
        }),
        __metadata("design:type", String)
    ], Payment.prototype, "date", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Сумма платежа в копейках',
        }),
        __metadata("design:type", Number)
    ], Payment.prototype, "amount", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelPropertyOptional)({
            description: 'Дополнительный комментарий',
        }),
        __metadata("design:type", String)
    ], Payment.prototype, "comment", void 0);
    return Payment;
}());
exports.Payment = Payment;
/**
 * Группа платежей
 */
var PaymentsGroup = /** @class */ (function () {
    function PaymentsGroup() {
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Название группы',
        }),
        __metadata("design:type", String)
    ], PaymentsGroup.prototype, "title", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Список платежей в группе',
        }),
        __metadata("design:type", Array)
    ], PaymentsGroup.prototype, "payments", void 0);
    return PaymentsGroup;
}());
exports.PaymentsGroup = PaymentsGroup;
/**
 * Статус заработной платы
 */
var SalaryState;
(function (SalaryState) {
    /**
     * Расчётная заработная плата в будущем
     */
    SalaryState["FUTURE"] = "FUTURE";
    /**
     * Выплаченная и зафиксированная запись
     */
    SalaryState["ARCHIVE"] = "ARCHIVE";
})(SalaryState = exports.SalaryState || (exports.SalaryState = {}));
var WorkSheetProject = /** @class */ (function () {
    function WorkSheetProject() {
        this.key = '';
        this.hours = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Ключ проекта в Jira',
        }),
        __metadata("design:type", String)
    ], WorkSheetProject.prototype, "key", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Количество часов затраченных на проект',
        }),
        __metadata("design:type", Number)
    ], WorkSheetProject.prototype, "hours", void 0);
    return WorkSheetProject;
}());
exports.WorkSheetProject = WorkSheetProject;
var SalarySource = /** @class */ (function () {
    function SalarySource() {
        this.prognosis = 0;
        this.worked = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Стаж сотрудника в часах за всё время работы',
        }),
        __metadata("design:type", Number)
    ], SalarySource.prototype, "seniority", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Прогноз отработки (средняя выработка за 6 предыдущих месяцев)',
        }),
        __metadata("design:type", Number)
    ], SalarySource.prototype, "prognosis", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Количество выработанных часов в месяце',
        }),
        __metadata("design:type", Number)
    ], SalarySource.prototype, "worked", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Количество использованных дней отпуска',
        }),
        __metadata("design:type", Number)
    ], SalarySource.prototype, "vacation", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Проекты в которых участвовал сотрудник',
            isArray: true,
            type: WorkSheetProject,
        }),
        __metadata("design:type", Array)
    ], SalarySource.prototype, "projects", void 0);
    return SalarySource;
}());
exports.SalarySource = SalarySource;
var SalaryBonus = /** @class */ (function () {
    function SalaryBonus() {
        this.name = '';
        this.amount = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Название бонуса',
        }),
        __metadata("design:type", String)
    ], SalaryBonus.prototype, "name", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Сумма бонуса в копейках',
        }),
        __metadata("design:type", Number)
    ], SalaryBonus.prototype, "amount", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дополнительный комментарий',
        }),
        __metadata("design:type", String)
    ], SalaryBonus.prototype, "comment", void 0);
    return SalaryBonus;
}());
exports.SalaryBonus = SalaryBonus;
var SalaryWage = /** @class */ (function () {
    function SalaryWage() {
        this.work = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Ставка в копейках\n' +
                'Алгоритм расчёта зависит от режима расчёта зарплаты\n' +
                '* TIME_AND_MATERIAL - не используется\n' +
                '* INSURED_* - (прогноз * рейт * цена * 0.5)',
        }),
        __metadata("design:type", Number)
    ], SalaryWage.prototype, "insurance", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Бонус за стаж в копейках\n' +
                'Алгоритм расчёта зависит от режима расчёта зарплаты\n' +
                '* TIME_AND_MATERIAL - не используется\n' +
                '* INSURED_* - (стаж * рейт * цена * 0.125)',
        }),
        __metadata("design:type", Number)
    ], SalaryWage.prototype, "seniority", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Бонус за выработку в копейках\n' +
                'Алгоритм расчёта зависит от режима расчёта зарплаты\n' +
                '* TIME_AND_MATERIAL - (рейт * цена)\n' +
                '* INSURED_* - часы = выработка + (отпуск * прогноз / 28);  ((min(160, часы) + max(часы - 160, 0) * 0.5) * рейт * цена * 0.5)',
        }),
        __metadata("design:type", Number)
    ], SalaryWage.prototype, "work", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Прочие бонусы',
            isArray: true,
            type: SalaryBonus,
        }),
        __metadata("design:type", Array)
    ], SalaryWage.prototype, "bonus", void 0);
    return SalaryWage;
}());
exports.SalaryWage = SalaryWage;
var SalaryPayment = /** @class */ (function () {
    function SalaryPayment() {
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Аванс',
            isArray: true,
            type: Payment,
        }),
        __metadata("design:type", Array)
    ], SalaryPayment.prototype, "advance", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Безналичные оплаты',
            isArray: true,
            type: Payment,
        }),
        __metadata("design:type", Array)
    ], SalaryPayment.prototype, "wire", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Наличные оплаты',
            isArray: true,
            type: Payment,
        }),
        __metadata("design:type", Array)
    ], SalaryPayment.prototype, "cash", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Возврат задолженности',
            isArray: true,
            type: Payment,
        }),
        __metadata("design:type", Array)
    ], SalaryPayment.prototype, "reimbursement", void 0);
    return SalaryPayment;
}());
exports.SalaryPayment = SalaryPayment;
var SalaryAccount = /** @class */ (function () {
    function SalaryAccount() {
        this.payments = [];
        this.amount = 0;
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Переводы на подотчёт',
            isArray: true,
            type: Payment,
        }),
        __metadata("design:type", Array)
    ], SalaryAccount.prototype, "payments", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Состояние счёта на момент выплаты зарплаты',
        }),
        __metadata("design:type", Number)
    ], SalaryAccount.prototype, "amount", void 0);
    return SalaryAccount;
}());
exports.SalaryAccount = SalaryAccount;
/**
 * Заработная плата
 */
var Salary = /** @class */ (function () {
    function Salary() {
        /**
         * Идентификатор записи (не используется). Рабочий идентификатор - это пара полей user + date
         */
        this._id = new bson_1.ObjectId();
        this.user = '';
        this.date = '';
        this.rate = 0;
        this.course = 0;
        this.mode = SalaryMode.INSURED_2024;
        this.state = SalaryState.FUTURE;
        this.src = new SalarySource();
        this.wage = new SalaryWage();
    }
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Сотрудник получающий зарплату',
        }),
        __metadata("design:type", String)
    ], Salary.prototype, "user", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Дата зарплаты в формате `YYYY-MM`',
        }),
        __metadata("design:type", String)
    ], Salary.prototype, "date", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Рейт сотрудника (в копейках в час)',
        }),
        __metadata("design:type", Number)
    ], Salary.prototype, "rate", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Курс зарплатной условной единицы',
        }),
        __metadata("design:type", Number)
    ], Salary.prototype, "course", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Алгоритм расчёта заработной платы',
            enum: [SalaryMode.TSR, SalaryMode.INSURED_SENIORITY, SalaryMode.TIME_AND_MATERIAL, SalaryMode.INSURED_2024],
        }),
        __metadata("design:type", String)
    ], Salary.prototype, "mode", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Состояние записи',
            enum: [SalaryState.ARCHIVE, SalaryState.FUTURE],
        }),
        __metadata("design:type", String)
    ], Salary.prototype, "state", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Исходные данные для расчётов',
        }),
        __metadata("design:type", SalarySource)
    ], Salary.prototype, "src", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Заработная плата',
        }),
        __metadata("design:type", SalaryWage)
    ], Salary.prototype, "wage", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Задолженность по зарплате в копейках',
        }),
        __metadata("design:type", Number)
    ], Salary.prototype, "arrears", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Выплата зарплаты',
        }),
        __metadata("design:type", SalaryPayment)
    ], Salary.prototype, "payment", void 0);
    __decorate([
        (0, api_model_property_decorator_1.ApiModelProperty)({
            description: 'Состояние подотчётного счёта',
        }),
        __metadata("design:type", SalaryAccount)
    ], Salary.prototype, "account", void 0);
    return Salary;
}());
exports.Salary = Salary;
