import React, { Component } from 'react';
import { Salary } from 'salary-shared';
import { fmt } from '../../utils/format';
import { RowDetails } from './RowDetails';

export interface UserSalaryTsrProps {
	salary: Salary;
}

export interface UserSalaryTsrState {
}

export class UserSalaryTsr extends Component<UserSalaryTsrProps, UserSalaryTsrState> {
	render() {
		const { salary } = this.props;
		return (<RowDetails {...{
			caption: 'Выработка',
			data: `${fmt.amount(salary.src.worked)} ч.`,
			comment: ``,
		}} />);
	}
}
