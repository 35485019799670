import { connect } from 'react-redux';
import { PageAdminVacations as Page, PageVacationsProps } from '../components/PageAdminVacations/PageAdminVacations';
import { IState, State } from '../models/State';
import { concat, sortBy } from 'lodash';
import { moment } from 'salary-shared';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { DispatchFunc } from '../utils/redux';

const currentYear = moment().format('YYYY');
const _year = (m?: string) => m || currentYear;

export const PageAdminVacations = connect<{}, {}, PageVacationsProps, IState>(
	(state, ownProps) => {
		const year = _year(ownProps.match.params.year);

		return {
			year: year,
			users: State.model.users.working(state),
			vacations: sortBy(State.model.vacations.byYear(state)[year], 'user'),
		};
	},
	(dispatch: DispatchFunc, ownProps) => {
		const year = _year(ownProps.match.params.year);

		return {
			onInit: () =>
				Promise.all([Api.users.find({}), Api.vacations.forYear(year)])
					.then(resps =>
						concat(ActionEntity.loadResp(Schema.users!)(resps[0]), ActionEntity.loadResp(Schema.vacations!)(resps[1])),
					)
					.then(dispatch),
		};
	},
)(Page);
