import React, { Component } from 'react';
import { default as classnames } from 'classnames';
import { Link } from 'react-router-dom';
import { url } from '../../sitemap';
import { moment, User } from 'salary-shared';

import './Navbar.scss';
import { currentMonth } from '../../utils/utils';

export interface NavbarProps {
	className: string;
	me?: User;
}

export interface NavbarState {
}

export class Navbar extends Component<NavbarProps, NavbarState> {
	render() {
		const { className, me } = this.props;
		const prev = moment().add(-1, 'month').format('YYYY-MM');
		const curr = currentMonth;
		const cYear = moment().format('YYYY');
		return (
			<nav className={classnames(className, 'Navbar')}>
				{me && (
					<div className='Navbar__user'>
						<h3 className='Navbar__user-header'>{me.name}</h3>
						<ul className='Navbar__list'>
							<li className='Navbar__item'>
								<Link to={url.home}>Начало</Link>
							</li>
							<li className='Navbar__item'>Зарплата:</li>
							<li className='Navbar__item'>
								<Link to={url.user.salary.month(prev)}>{prev}</Link>
							</li>
							<li className='Navbar__item'>
								<Link to={url.user.salary.month(curr)}>{curr}</Link>
							</li>
						</ul>
					</div>
				)}
				{me && me.isAdmin && <div className='Navbar__spacer' />}
				{me && me.isAdmin && (
					<div className='Navbar__admin'>
						<h5 className='Navbar__admin-header'>(Администратор)</h5>
						<ul className='Navbar__list'>
							<li className='Navbar__item'>
								<Link to={url.admin.users.home}>Сотрудники</Link>
							</li>
							<li className='Navbar__item'>Зарплата:</li>
							<li className='Navbar__item'>
								<Link to={url.admin.salary.month(prev)}>{prev}</Link>
							</li>
							<li className='Navbar__item'>
								<Link to={url.admin.salary.month(curr)}>{curr}</Link>
							</li>
							<li className='Navbar__item'>
								<Link to={url.admin.vacations.year(cYear)}>Отпуска</Link>
							</li>
						</ul>
					</div>
				)}
			</nav>
		);
	}
}
