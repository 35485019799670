import { connect } from 'react-redux';
import { PageUserInfo as Page, PageUserInfoProps } from '../components/PageUserInfo/PageUserInfo';
import { IState, State } from '../models/State';
import { meId } from '../utils/utils';
import { concat } from 'lodash';
import { Api } from '../api/Api';
import { ActionEntity } from '../reducers/ActionsEntity';
import { Schema } from '../models/Schema';
import { DispatchFunc } from '../utils/redux';

export const PageUserInfo = connect<{}, {}, PageUserInfoProps, IState>(
	(state, ownProps) => {
		const userId = meId();

		return {
			me: State.entity.users(state)[userId],
			courses: State.entity.courses(state),
		};
	},
	(dispatch: DispatchFunc, ownProps) => {
		return {
			onInit: () =>
				Promise.all([
					Api.users.find({
						_id: meId(),
					}),
					Api.courses.all(),
				])
					.then(resps =>
						concat(ActionEntity.loadResp(Schema.users!)(resps[0]), ActionEntity.loadResp(Schema.courses!)(resps[1]))
					)
					.then(dispatch),
		};
	}
)(Page);
