import { toLower, isNil, join, each, uniq } from 'lodash';
import { config } from './config';

const platform = require('platform');

function _tag(prefix: string, value: string) {
	return toLower(`${prefix}-${value}`).replace(/[^0-9a-z]+/g, '-');
}

function _header(value: any) {
	return `* *${value}*\n`;
}

function _kv(key: string, value: any) {
	if (isNil(value)) {
		return '';
	} else {
		return `    ** _${key}:_ ${value}\n`;
	}
}

function _systemInformation() {
	const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	let result =
		_header('System') +
		_kv('Platform', platform.description) +
		_kv('Screen', `${window.screen.width}x${window.screen.height} (${window.devicePixelRatio} dppx)`) +
		_kv('Viewport', `${vw}x${vh}`) +
		_kv('Document', `${document.body.scrollWidth}x${document.body.scrollHeight}`) +
		_kv('Time', new Date().toISOString());
	return result;
}

function _applicationInformation() {
	let result =
		_header('Application') +
		_kv('Url', window.location.href) +
		_kv('Version', `${config.version.str} - ${config.version.profile}`);
	return result;
}

function _sessionInformation(session: any) {
	let result = _header('Session') + _kv('User', session.userId);
	return result;
}

function _consoleLog() {
	if (window.consoleLog) {
		let result = _header('Console');
		each(window.consoleLog, (l: any) => {
			result += `    ** _${l.t}_ *${l.m}:* ${join(l.a, ' ')}\n`;
		});
		return result;
	} else {
		return '';
	}
}

const report = (session: any) =>
	'' +
	_systemInformation() +
	'\n' +
	_applicationInformation() +
	'\n' +
	(session ? _sessionInformation(session) + '\n' : '') +
	_consoleLog() +
	'\n';

const tags = () => {
	const result = [];
	if (platform.os.family) {
		result.push(_tag('os', platform.os.family));
		if (platform.os.version) {
			result.push(_tag('os', `${platform.os.family}-${platform.os.version}`));
		}
	}
	if (platform.os.architecture) {
		result.push(_tag('arch', platform.os.architecture));
	}
	if (platform.name) {
		result.push(_tag('browser', platform.name));
		if (platform.version) {
			result.push(_tag('browser', `${platform.name}-${platform.version}`));
		}
	}
	if (platform.layout) {
		result.push(_tag('browser-layout', platform.layout));
	}
	return uniq(result);
};

export const troubleshoot = {
	report,
	tags,
};
