import React, { Component } from 'react';
import { default as classnames } from 'classnames';
import { fmt } from '../../utils/format';
import { PaymentsGroup } from 'salary-shared';
import { map, sumBy } from 'lodash';

import './SalarySummary.scss';

export interface SalarySummaryProps {
	lastPaymentDate?: string;
	payments: PaymentsGroup[];
}

export class SalarySummary extends Component<SalarySummaryProps> {
	render() {
		const { lastPaymentDate, payments } = this.props;

		return (
			<div
				{...{
					className: classnames('SalarySummary'),
				}}
			>
				{lastPaymentDate &&
					map(payments, (pg, i) => (
						<table key={`payment-group-${i}`} className="SalarySummary__payment">
							<caption>
								Выплата{' '}
								<strong>
									{fmt.date(lastPaymentDate)} - {pg.title}
								</strong>
							</caption>
							<tbody>
								{map(pg.payments, (p, j) => (
									<tr key={`payment-${i}-${j}`}>
										<td className="SalarySummary__user">{p.comment}</td>
										<td className="SalarySummary__amount">{fmt.rur(p.amount)}</td>
									</tr>
								))}
								<tr>
									<td className="SalarySummary__total-label">Итого:</td>
									<td className="SalarySummary__total-amount">{fmt.rur(sumBy(pg.payments, 'amount'))}</td>
								</tr>
							</tbody>
						</table>
					))}
			</div>
		);
	}
}
