import React, { Component } from 'react';

import { head, isNil, join, map, split } from 'lodash';

import {
	Salary,
	SalaryCalculator,
	SalaryMode,
	SalaryPaymentCalculator,
	SalarySourceCalculator,
	SalaryWageCalculator,
	User,
} from 'salary-shared';

import './PageUserSalaryList.scss';
import { url } from '../../sitemap';
import { Link } from 'react-router-dom';
import { fmt } from '../../utils/format';

export interface PageUserSalaryListProps {
	user: User;
	onInit: () => void;
	salaries: Salary[];
}

export interface PageUserSalaryListState {
}

const abbr = (name: string): string => join(
	map(
		split(name, /[.\s]/),
		head,
	),
	'',
);

function noTsr(mode: SalaryMode, value: string): string {
	return SalaryMode.TSR !== mode ? value : '';
}

export class PageUserSalaryList extends Component<PageUserSalaryListProps, PageUserSalaryListState> {

	componentDidMount() {
		this.props.onInit();
	}

	render() {
		const { user, salaries } = this.props;

		if (isNil(user) || isNil(salaries)) {
			return (<div className='PageUserSalaryList'>
				<h2>Загружается...</h2>
			</div>);
		} else {
			document.title = `${abbr(user.name)}`;
			return (<div className='PageUserSalaryList'>
				<h4>Заработная плата</h4>
				<table>
					<thead>
					<tr>
						<th>Месяц</th>
						<th>Алгоритм</th>
						<th>Выработка</th>
						<th>Отпуск</th>
						<th>Начислено</th>
						<th>Выплачено</th>
						<th>Задолженность</th>
					</tr>
					</thead>
					<tbody>
					{map(salaries, s => <tr {...{ key: s.date }}>
						<td className='text-center'><Link to={url.user.salary.month(s.date)}>{fmt.month(s.date)}</Link></td>
						<td>{fmt.salaryMode(s.mode)}</td>
						<td className='text-right text-fixed'>{fmt.hours(s.src.worked)}</td>
						<td
							className='text-right text-fixed'>{noTsr(s.mode, fmt.hours(SalarySourceCalculator.vacationHours(s.src)))}</td>
						<td className='text-right text-fixed'>{noTsr(s.mode, fmt.rur(SalaryWageCalculator.total(s.wage)))}</td>
						<td
							className='text-right text-fixed'>{noTsr(s.mode, fmt.rur(SalaryPaymentCalculator.total(s.payment)))}</td>
						<td className='text-right text-fixed'>{noTsr(s.mode, fmt.rur(SalaryCalculator.arrearsAfter(s)))}</td>
					</tr>)}
					</tbody>
				</table>
			</div>);
		}

	}
}
