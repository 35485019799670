import React, { Component } from 'react';
import { User, UserCalculator, Vacation } from 'salary-shared';
import { filter, sumBy } from 'lodash';

import './YearRow.scss';

export interface YearRowProps {
	year: string;
	user: User;
	vacations: Vacation[];
}

export interface YearRowState {
}

export class YearRow extends Component<YearRowProps, YearRowState> {
	render() {
		const { year, user, vacations } = this.props;
		const eoYear = `${year}-12-31`;
		const days = UserCalculator.vacationDays(user, eoYear);
		const used = sumBy(vacations, 'days');

		function _used(month: string): string {
			const res = sumBy(
				filter(vacations, v => `${year}-${month}` === v.date),
				'days',
			);
			if (res > 0) {
				return '' + res;
			} else {
				return '';
			}
		}

		return (
			<tr className='YearRow'>
				<td className='YearRow__td--year'>{year}</td>
				<td className='YearRow__td--seniority'>{UserCalculator.seniority(user, eoYear)}</td>
				<td className='YearRow__td--days'>{days}</td>
				<td className='YearRow__td--month'>{_used('01')}</td>
				<td className='YearRow__td--month'>{_used('02')}</td>
				<td className='YearRow__td--month'>{_used('03')}</td>
				<td className='YearRow__td--month'>{_used('04')}</td>
				<td className='YearRow__td--month'>{_used('05')}</td>
				<td className='YearRow__td--month'>{_used('06')}</td>
				<td className='YearRow__td--month'>{_used('07')}</td>
				<td className='YearRow__td--month'>{_used('08')}</td>
				<td className='YearRow__td--month'>{_used('09')}</td>
				<td className='YearRow__td--month'>{_used('10')}</td>
				<td className='YearRow__td--month'>{_used('11')}</td>
				<td className='YearRow__td--month'>{_used('12')}</td>
				<td className='YearRow__td--used'>{used}</td>
				<td className='YearRow__td--left'>{days - used}</td>
			</tr>
		);
	}
}
