import React, { Component, Fragment } from 'react';
import {
	monthWorkHours,
	Salary,
	SalaryCalculator,
	SalaryMode,
	SalaryPaymentCalculator,
	SalarySourceCalculator,
	SalaryWageCalculator,
	User,
	UserCalculator,
} from 'salary-shared';
import { head, isNil, last, split } from 'lodash';
import { fmt } from '../../utils/format';

import './RowSalary.scss';
import { Link } from 'react-router-dom';
import { url } from '../../sitemap';

export interface RowSalaryProps {
	user: User;
	salary: Salary;
	month: string;
}

export interface RowSalaryState {
}

const abbr = (name: string) => {
	const names = split(name, ' ');
	return `${head(names)} ${(last(names) || ' ')[0]}.`;
};

export class RowSalary extends Component<RowSalaryProps, RowSalaryState> {
	render() {
		const { user, salary, month } = this.props;
		if (isNil(user) || isNil(salary)) {
			return null;
		}
		if (user.end && user.end < month) {
			return null;
		}
		const seniority = UserCalculator.seniority(user, salary.date);
		if (SalaryMode.TSR === salary.mode) {
			return (
				<Fragment>
					<tr className='RowSalary'>
						<td rowSpan={2} className='RowSalary__name'>
							<Link to={url.admin.users.salary(user._id, month)}>{abbr(user.name)}</Link>
							<br />
							<span className={'RowSalary__name-tag'}>{fmt.salaryMode(salary.mode)}</span>
							<br />
							<span className={'RowSalary__name-tag'}>({fmt.salaryState(salary.state)})</span>
						</td>
						<td rowSpan={2} className='RowSalary__src'>
							{fmt.seniority(seniority)}
						</td>
						<td rowSpan={2} className='RowSalary__src'>
						</td>
						<td rowSpan={2} className='RowSalary__src'>
							{fmt.hours(salary.src.worked)}
						</td>
						<td rowSpan={2} className='RowSalary__src'>
						</td>
						<td colSpan={4} className='RowSalary__total'>
						</td>
						<td colSpan={3} className='RowSalary__total'>
						</td>
						<td colSpan={3} className='RowSalary__total'>
						</td>
					</tr>
				</Fragment>);
		}
		return (
			<Fragment>
				<tr className='RowSalary'>
					<td rowSpan={2} className='RowSalary__name'>
						<Link to={url.admin.users.salary(user._id, month)}>{abbr(user.name)}</Link>
						<br />
						<span className={'RowSalary__name-tag'}>{fmt.salaryMode(salary.mode)}</span>
						<br />
						<span className={'RowSalary__name-tag'}>({fmt.salaryState(salary.state)})</span>
					</td>
					<td rowSpan={2} className='RowSalary__src'>
						{fmt.seniority(seniority)}
						{salary.src.seniority && <Fragment>
							<br />
							{fmt.seniorityH(salary.src.seniority)}
							<br />
							{fmt.seniorityHM(salary.src.seniority, monthWorkHours)}
						</Fragment>}
					</td>
					<td rowSpan={2} className='RowSalary__src'>
						{fmt.hours(salary.src.prognosis)}
					</td>
					<td rowSpan={2} className='RowSalary__src'>
						{fmt.hours(salary.src.worked)}
					</td>
					<td rowSpan={2} className='RowSalary__src'>
						{fmt.hours(SalarySourceCalculator.vacationHours(salary.src))}
					</td>
					<td colSpan={4} className='RowSalary__total'>
						{fmt.rur(SalaryWageCalculator.total(salary.wage))}
					</td>
					<td colSpan={3} className='RowSalary__total'>
						{fmt.rur(SalaryPaymentCalculator.total(salary.payment))}
					</td>
					<td colSpan={3} className='RowSalary__total'>
						{fmt.rur(SalaryCalculator.arrearsAfter(salary))}
					</td>
				</tr>
				<tr className='RowSalary'>
					<td className='RowSalary__detail'>{fmt.rur(salary.wage.insurance)}</td>
					<td className='RowSalary__detail'>{fmt.rur(salary.wage.seniority)}</td>
					<td className='RowSalary__detail'>{fmt.rur(salary.wage.work)}</td>
					<td className='RowSalary__detail RowSalary__detail--right-border'>
						{fmt.rur(SalaryWageCalculator.bonuses(salary.wage))}
					</td>
					<td className='RowSalary__detail'>{fmt.rur(SalaryPaymentCalculator.sum((salary.payment || {}).advance))}</td>
					<td className='RowSalary__detail'>{fmt.rur(SalaryPaymentCalculator.sum((salary.payment || {}).wire))}</td>
					<td className='RowSalary__detail RowSalary__detail--right-border'>
						{fmt.rur(SalaryPaymentCalculator.sum((salary.payment || {}).cash))}
					</td>
					<td className='RowSalary__detail'>{fmt.rur(salary.arrears)}</td>
					<td className='RowSalary__detail'>
						{fmt.rur(SalaryWageCalculator.total(salary.wage) - SalaryPaymentCalculator.total(salary.payment))}
					</td>
					<td className='RowSalary__detail RowSalary__detail--right-border'>
						{fmt.rur(SalaryPaymentCalculator.sum((salary.payment || {}).reimbursement))}
					</td>
				</tr>
			</Fragment>
		);
	}
}
