import { createSelector, Selector } from 'reselect';
import { Dictionary, filter, groupBy, isNil, sortBy, values } from 'lodash';

import { Course, Salary, User, Vacation } from 'salary-shared';

import { LockState } from '../reducers/ReducerLock';

import { UserState } from '../reducers/ReducerUser';

export interface IState {
	lock: LockState;
	user: UserState;
	entity: {
		users: Dictionary<User>;
		courses: Dictionary<Course>;
		salaries: Dictionary<Salary>;
		vacations: Dictionary<Vacation>;
	};
}

interface SelectorMap {
	[key: string]: Selector<IState, Dictionary<any>>;

	users: Selector<IState, Dictionary<User>>;
	courses: Selector<IState, Dictionary<Course>>;
	salaries: Selector<IState, Dictionary<Salary>>;
	vacations: Selector<IState, Dictionary<Vacation>>;
}

const entity: SelectorMap = {
	users: s => s.entity.users || {},
	courses: s => s.entity.courses || {},
	salaries: s => s.entity.salaries || {},
	vacations: s => s.entity.vacations || {},
};

export const State = {
	lock: (store: any) => store.lock,
	user: (store: any) => store.user,
	entity,
	model: {
		salaries: {
			all: entity.salaries,
			byUser: createSelector<IState, Dictionary<Salary>, Dictionary<Salary[]>>(entity.salaries, salaries =>
				groupBy(salaries, 'user'),
			),
			byDate: createSelector<IState, Dictionary<Salary>, Dictionary<Salary[]>>(entity.salaries, salaries =>
				groupBy(salaries, 'date'),
			),
		},
		vacations: {
			all: entity.vacations,
			byUser: createSelector<IState, Dictionary<Vacation>, Dictionary<Vacation[]>>(entity.vacations, vacations =>
				groupBy(vacations, 'user'),
			),
			byYear: createSelector<IState, Dictionary<Vacation>, Dictionary<Vacation[]>>(entity.vacations, vacations =>
				groupBy(vacations, v => v.date.substr(0, 4)),
			),
		},
		users: {
			all: entity.users,
			working: createSelector<IState, Dictionary<User>, User[]>(entity.users, users => sortBy(filter(values(users), u => isNil(u.end)), 'start')),
			sortedByStart: createSelector<IState, Dictionary<User>, User[]>(entity.users, users => sortBy(values(users), 'start')),
		},
	},
};
