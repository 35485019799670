import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// And use redux-batch as an example of adding enhancers
import { reduxBatch } from '@manaflair/redux-batch';

import { reducer } from './reducers';

const middleware = [...getDefaultMiddleware()];

const preloadedState = {};

export const store = configureStore({
	reducer,
	middleware,
	devTools: process.env.NODE_ENV !== 'production',
	preloadedState,
	enhancers: [reduxBatch],
});

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were automatically composed together
