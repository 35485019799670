import { State } from '../models/State';
import { store } from '../store';
import { moment } from 'salary-shared';

export const currentMonth = moment().format('YYYY-MM');

export const yearStart = 2019;

export const month = (m?: string) => m || currentMonth;

export const meId = () => {
	const user = State.user(store.getState());
	if (user.me) {
		return user.me._id;
	} else {
		return null;
	}
};

