"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCalculator = exports.SalaryCalculator = exports.SalarySourceCalculator = exports.VacationCalculator = exports.SalaryPaymentCalculator = exports.SalaryWageCalculator = exports.hours2money = exports.round4 = exports.round100 = void 0;
var lodash_1 = require("lodash");
var model_1 = require("./model");
var moment_1 = require("./moment");
var consts_1 = require("./consts");
var monthDays = 28;
var round100 = function (amount) { return Math.round((amount !== null && amount !== void 0 ? amount : 0) * 100) / 100; };
exports.round100 = round100;
var round4 = function (value) { return Math.round(value * 4) / 4; };
exports.round4 = round4;
/**
 * Конвертирует часы в сумму с копейках
 * @param hours
 * @param rate
 * @param course
 */
var hours2money = function (hours, rate, course) { return (0, exports.round100)(hours * rate * course / 100); };
exports.hours2money = hours2money;
exports.SalaryWageCalculator = {
    total: function (wage) {
        return ((wage && wage.work + (wage.insurance || 0) + (wage.seniority || 0) + exports.SalaryWageCalculator.bonuses(wage)) || 0);
    },
    bonuses: function (wage) { return (0, lodash_1.sumBy)(wage.bonus, 'amount'); },
};
exports.SalaryPaymentCalculator = {
    total: function (payment) {
        return ((payment && (0, lodash_1.sumBy)(payment.advance, 'amount') + (0, lodash_1.sumBy)(payment.wire, 'amount') + (0, lodash_1.sumBy)(payment.cash, 'amount')) || 0);
    },
    sum: function (payments) { return (0, lodash_1.sumBy)(payments, 'amount'); },
};
exports.VacationCalculator = {
    /**
     * Вычисляет количество часов которые получатся при данном прогнозе (prognosis) если потратить заданное количество отпускных дней (days)
     * @param days количество отпускных дней
     * @param prognosis прогноз в часах
     */
    hours: function (days, prognosis) {
        return (0, exports.round100)(prognosis / monthDays * days);
    },
    /**
     * Вычисляет количество отпускных дней, которые можно потратить при заданном прогнозе (prognosis), чтобы минимально превысить указанное количество часов (hours)
     * @param hours
     * @param prognosis
     */
    days: function (hours, prognosis) {
        var dayHours = exports.VacationCalculator.hours(1, prognosis);
        return Math.round(hours / dayHours);
    },
};
exports.SalarySourceCalculator = {
    /**
     * Вычисляет количество отпускных часов для заданных отпускных дней и прогноза
     * @param src
     */
    vacationHours: function (src) {
        return exports.VacationCalculator.hours(src.vacation || 0, src.prognosis || 0);
    },
    /**
     * Вычисляет полное количество часов для премии за выработку
     * @param src
     */
    totalHours: function (src) {
        return (src.worked || 0) + exports.SalarySourceCalculator.vacationHours(src);
    },
    /**
     * Вычисляет прогнозные часы для алгоритма 2024 года
     * @param src
     */
    prognosisHours: function (src) {
        return Math.max(src.worked, Math.min(exports.SalarySourceCalculator.totalHours(src), src.prognosis || 0, consts_1.monthWorkHours));
    },
    /**
     * Вычисляет прогнозные часы в зависимости от выбранного алгоритма
     * @param src
     * @param mode
     */
    prognosisHoursForMode: function (src, mode) {
        switch (mode) {
            case model_1.SalaryMode.TIME_AND_MATERIAL:
            case model_1.SalaryMode.INSURED_SENIORITY:
                return exports.SalarySourceCalculator.totalHours(src);
            case model_1.SalaryMode.INSURED_2024:
                return exports.SalarySourceCalculator.prognosisHours(src);
        }
        return 0;
    },
    /**
     * Вычисляет количество часов, которые необходимо доработать для достижения прогноза
     * прогноз - выработка - отпускные часы
     * @param src
     */
    hoursToPrognosis: function (src) {
        return src.prognosis - exports.SalarySourceCalculator.totalHours(src);
    },
    wage: {
        /**
         * Формула расчёта премии за выработку в зависимости от выбранного алгоритма
         * @param src исходные данные
         * @param mode алгоритм расчёта
         * @param rate персональный рейт сотрудника
         * @param course внутренний курс условной единицы
         */
        work: function (src, mode, rate, course) {
            switch (mode) {
                case model_1.SalaryMode.TIME_AND_MATERIAL:
                    return (0, exports.hours2money)(src.worked, rate, course);
                case model_1.SalaryMode.INSURED_SENIORITY:
                case model_1.SalaryMode.INSURED_2024:
                    // часы = выработка + (отпуск * прогноз / 28);
                    var hours = exports.SalarySourceCalculator.totalHours(src);
                    // ((min(160, часы) + max(часы - 160, 0) * 0.5) * рейт * цена * 0.5)
                    var payHours = (0, exports.round100)((Math.min(160, hours) + Math.max(hours - 160, 0) * 0.5) * 0.5);
                    return (0, exports.hours2money)(payHours, rate, course);
            }
            return undefined;
        },
        /**
         * Формула расчёта страховки в зависимости от выбранного алгоритма
         * @param src исходные данные
         * @param mode алгоритм расчёта
         * @param rate персональный рейт сотрудника
         * @param course внутренний курс условной единицы
         */
        insurance: function (src, mode, rate, course) {
            switch (mode) {
                case model_1.SalaryMode.INSURED_SENIORITY:
                case model_1.SalaryMode.INSURED_2024:
                    // (прогноз * рейт * цена * 0.5)
                    return (0, exports.hours2money)(src.prognosis * 0.5, rate, course);
            }
            return undefined;
        },
        /**
         * Формула расчёта премии за стаж в зависимости от выбранного алгоритма
         * @param src исходные данные
         * @param mode алгоритм расчёта
         * @param seniorityM трудовой стаж сотрудника в месяцах
         * @param rate персональный рейт сотрудника
         * @param course внутренний курс условной единицы
         */
        seniority: function (src, mode, seniorityM, rate, course) {
            var _a;
            switch (mode) {
                case model_1.SalaryMode.INSURED_SENIORITY:
                    // (стаж * рейт * цена * 0.125)
                    return (0, exports.hours2money)(seniorityM * 0.125, rate, course);
                case model_1.SalaryMode.INSURED_2024:
                    // (стаж * рейт * цена * 0.125)
                    var seniority = (0, exports.round100)(((_a = src.seniority) !== null && _a !== void 0 ? _a : 0) / consts_1.monthWorkHours);
                    return (0, exports.hours2money)(seniority * 0.125, rate, course);
            }
            return undefined;
        },
    },
};
exports.SalaryCalculator = {
    arrearsAfter: function (salary) {
        return ((salary.arrears || 0) +
            exports.SalaryWageCalculator.total(salary.wage) -
            exports.SalaryPaymentCalculator.total(salary.payment) -
            ((salary.payment && exports.SalaryPaymentCalculator.sum(salary.payment.reimbursement)) || 0) || 0);
    },
};
exports.UserCalculator = {
    rateForDate: function (rate, date) {
        var result = (0, lodash_1.filter)(rate, function (s) { return s.date <= date; });
        result = (0, lodash_1.sortBy)(result, 'date');
        result = (0, lodash_1.reverse)(result);
        result = (0, lodash_1.map)(result, 'rate');
        result = (0, lodash_1.head)(result);
        return result || 0;
    },
    modeForDate: function (salary, date) {
        var result = (0, lodash_1.filter)(salary, function (s) { return s.date <= date; });
        result = (0, lodash_1.sortBy)(result, 'date');
        result = (0, lodash_1.reverse)(result);
        result = (0, lodash_1.map)(result, 'mode');
        result = (0, lodash_1.head)(result);
        return result || model_1.SalaryMode.TIME_AND_MATERIAL;
    },
    seniority: function (user, date) {
        return (0, moment_1.moment)(date).diff(user === null || user === void 0 ? void 0 : user.start, 'months');
    },
    vacationDays: function (user, date) {
        var _s = exports.UserCalculator.seniority(user, date);
        if (date < '2024') {
            return Math.min(14 + Math.round(((_s - 6) / 54) * 14), monthDays);
        }
        else {
            return Math.min(14 + Math.round(((_s - 6) / 72) * 42), monthDays * 2);
        }
    },
};
