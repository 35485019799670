import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'reflect-metadata';

import './index.scss';

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { Api } from './api/Api';
import { State } from './models/State';

window.HitsoftSalary = {
	run: function(config: any) {
		const apiUrl = config?.apiUrl ?? '';
		const token = config?.token ?? '';

		Api.init(`${apiUrl}/api-json`, token).then(() => {
			const user = State.user(store.getState());
			ReactDOM.render(
				<Provider store={store}>
					<App me={user.me} />
				</Provider>,
				document.getElementById('root')
			);
		});

		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		serviceWorker.unregister();
	},
};
